import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Typed from 'typed.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import contact1 from "../assets/contact-info-1.jpg";
import contact2 from "../assets/contact-info-2.jpg";
import contact3 from "../assets/contact-info-3.jpg";
import { PiMapPinLight } from 'react-icons/pi';
import { BsSendFill, BsInstagram, BsTwitter, BsYoutube } from "react-icons/bs";
import { FaArrowRight, FaFacebook, FaPhoneAlt } from "react-icons/fa";
import { LiaLinkedin } from "react-icons/lia";
import axios from 'axios';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [statusMessage, setStatusMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check if any field is empty
    if (!formData.name || !formData.email || !formData.subject || !formData.message) {
      setStatusMessage("Please fill in all the fields.");
      return;
    }
  
    setIsSubmitting(true);
    setStatusMessage(""); // Reset any previous status message
  
    try {
      const response = await axios.post("https://server.solutionsoul.com/auth/company/contact-us", formData);
  
      if (response.status === 200) {
        setStatusMessage(response.data.message || "Message sent successfully!");
  
        // Clear form data after successful submission
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      }
    } catch (error) {
      if (error.response) {
        setStatusMessage(error.response.data.error || "Failed to submit the form.");
      } else {
        setStatusMessage("An unexpected error occurred.");
      }
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  

  const contactus = [
    {
      title: 'Address',
      contactdetail: 'Main Boulevard M-11 Mazzine Floor Sadique Trade Center Lahore',
      image: contact1,
      icons: PiMapPinLight
    },
    {
      title: 'Call Now',
      contactdetail: ' (042) 358 170 87',
      contactdetail2: '(+92) 318 4051 461',
      image: contact2,
      icons: FaPhoneAlt
    },
    {
      title: 'Email Us',
      contactdetail: 'info@solutionsoul.com',
      contactdetail2: 'manager@solutionsoul.com',
      image: contact3,
      icons: BsSendFill
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const typed = new Typed('.typing', {
      strings: ['Contact Us'],
      typeSpeed: 20,
      loop: false,
      showCursor: false,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  useEffect(() => {
    AOS.init({ duration: 600, once: true });
  }, []);

  return (
    <div className='font-manrope'>
    <Helmet>
   <title >Contact Us | Solution Soul</title>
   <meta
     name="description"
     content="Learn about Solution Soul, experts in web development, app development, SEO, and digital marketing. Discover how our team helps businesses grow through innovative solutions."
   />
 </Helmet>
 <div className="relative ">
   <div  className="w-full  h-96 bg-[#304f7c] bg-no-repeat text-white" >
   <div className="absolute inset-0 flex items-center justify-center text-center"  >
     <h2 className="text-5xl md:text-6xl lg:text-7xl font-bold leading-[3.25rem] ">
       <span className="typing"></span>
     </h2>
   </div>
 </div>
 </div>
 <section className="mx-auto px-4 py-16 md:px-6 lg:px-8 ">

 <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
   {contactus.map((contactus, index) => (
     <div
       key={index}
       className="group bg-[#F7F8FD] rounded-3xl shadow-md border border-white overflow-hidden flex flex-col"
       data-aos="fade-up"
       data-aos-delay={100 * (index + 1)} // Adjust delay based on the index
     >
       <div className="flex flex-1 flex-col p-5">
         <div className="flex justify-between items-center border-b px-2 py-5">
           <h3 className="text-3xl font-semibold w-1/2">{contactus.title}</h3>
           <div className="w-14 h-14 flex items-center justify-center bg-[#304f7c] text-white rounded-full border border-[#344f7c]  font-semibold ">
             <contactus.icons className="w-8 h-8" /> 
           </div>
         </div>
         <div className='py-4'>
         <p className="text-gray-500 px-2 py-1">{contactus.contactdetail}</p>
         <p className="text-gray-500 px-2 py-1">{contactus.contactdetail2}</p>
         </div>
         <Link to="/our-services">
           <img
             src={contactus.image}
             alt={contactus.title}
             loading='lazy'
             className="w-full object-cover rounded-2xl transition-transform duration-300 group-hover:scale-105"
           />
         </Link>
       </div>
     </div>
   ))}
 </div>
</section>
<div className=" mx-auto px-4 py-12">
 <div className="flex flex-col md:flex-row gap-12">
   <div className="md:w-1/2 px-12">
     <h3 className="text-[#379885] text-lg font-semibold">Contact Us</h3>
     <h1 className="text-5xl font-bold leading-tight md:text-6xl lg:text-7xl mb-4 md:mb-0">Get in touch with us today</h1>
     <p className="text-gray-600 mb-8 mt-4">
       Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium, totam
       rem aperiam, eaque ipsa quae ab illo inventore et.
     </p>
     <h2 className="text-xl font-semibold mb-4">Follow Us:</h2>
     <div className="flex space-x-4">
       {/* Social Media Links */}
       <Link
         to="https://www.facebook.com/people/SolutionSoul/61552713434856/"
         target="_blank"
         rel="noopener noreferrer"
         className="bg-[#304f7c] border-2 border-[#304f7c] p-2 rounded-full text-white hover:bg-white hover:text-[#304f7c] transition-colors"
         aria-label="Follow us on Facebook"
       >
         <FaFacebook className="w-6 h-6" />
       </Link>
       <Link
         to="https://www.instagram.com/"
         target="_blank"
         rel="noopener noreferrer"
         className="bg-[#304f7c] border-2 border-[#304f7c] p-2 rounded-full text-white hover:bg-white hover:text-[#304f7c] transition-colors"
         aria-label="Follow us on Instagram"
       >
         <BsInstagram className="w-6 h-6" />
       </Link>
       <Link
         to="https://www.linkedin.com/company/solution-soul"
         target="_blank"
         rel="noopener noreferrer"
         className="bg-[#304f7c] border-2 border-[#304f7c] p-2 rounded-full text-white hover:bg-white hover:text-[#304f7c] transition-colors"
         aria-label="Follow us on LinkedIn"
       >
         <LiaLinkedin className="w-6 h-6" />
       </Link>
       <Link
         to="https://www.youtube.com/@SolutionSoul1"
         target="_blank"
         rel="noopener noreferrer"
         className="bg-[#304f7c] border-2 border-[#304f7c] p-2 rounded-full text-white hover:bg-white hover:text-[#304f7c] transition-colors"
         aria-label="Follow us on Twitter"
       >
         <BsYoutube className="w-6 h-6" />
       </Link>
     </div>
   </div>

   <div className="md:w-1/2 px-10 py-8 bg-[#F7F8FD] rounded-3xl shadow-sm">
  <form className="space-y-4" onSubmit={handleSubmit}>
    <div>
      <label htmlFor="name" className="sr-only"> Name</label>
      <input
        id="name"
        name="name"
        type="text"
        placeholder="Enter Name"
        className="w-full p-3 border rounded-md"
        value={formData.name}
        onChange={handleChange}
        required
      />
    </div>

    <div>
      <label htmlFor="email" className="sr-only">Email</label>
      <input
        id="email"
        type="email"
        placeholder="Email"
        className="w-full p-3 border rounded-md"
        value={formData.email}
        onChange={handleChange}
        name='email'
        required
      />
    </div>

    <div>
      <label htmlFor="subject" className="sr-only">Subject</label>
      <input
        id="subject"
        type="text"
        placeholder="Subject"
        className="w-full p-3 border rounded-md"
        value={formData.subject}
        onChange={handleChange}
        name='subject'
        required
      />
    </div>

    <div>
      <label htmlFor="message" className="sr-only">Message</label>
      <textarea
        id="message"
        placeholder="Message"
        rows={6}
        className="w-full p-3 border rounded-md resize-none"
        value={formData.message}
        onChange={handleChange}
        name='message'
        required
      ></textarea>
    </div>

    {/* Display status/error message */}
    {statusMessage && (
      <div className="text-red-500 text-sm">{statusMessage}</div>
    )}

    <button
      className="w-full md:w-auto mt-4 md:mt-0 flex bg-[#304f7c] text-white px-6 py-3 font-semibold rounded-full border-2 border-[#344f7c] overflow-hidden transition-transform duration-300 will-change-transform z-0 items-center justify-center group hover:text-[#344f7c]"
      onClick={handleSubmit}
      disabled={isSubmitting}
    >
      {isSubmitting ? (
        <div role="status">
          <svg
            aria-hidden="true"
            className="inline w-8 h-8 text-[#304f7c] animate-spin "
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span >Send A Message...</span>
        </div>
      ) : (
        "Send A Message"
      )}

      <span className="absolute inset-0 bg-white rounded-full transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0 will-change-transform z-[-1]"></span>
    </button>
  </form>
</div>

 </div>
</div>
</div>
  );
};

export default ContactUs;
