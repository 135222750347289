import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaAngleDown, FaArrowRight, FaPhoneAlt } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { BiLock, BiUser } from "react-icons/bi";
import { CgLock } from "react-icons/cg";
import ChooseUsSection from '../../pages/ChooseUsSection';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { servicelistdata } from '../assets/servicelistdata';

const ServiceDetailPage = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const { serviceId } = useParams();

  // Format the service title the same way it was formatted in the URL
  const formatServiceId = (title) => title.toLowerCase().replace(/\s+/g, '-');

  const service = servicelistdata.find(s => formatServiceId(s.title) === serviceId);

  // Move the useEffect hooks here, before any return statements
  useEffect(() => {
    AOS.init({ duration: 600, once: true });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // This will move the user to the top of the page
  }, []);

  if (!service) {
    return <p>Service not found!</p>;
  }

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const models = [
    {
      title: 'Fixed Price Model',
      icons: BiLock,
      features: [
        'Fixed time frames',
        'Well-defined specification',
        'Low Risk',
        'Quick start',
        'Guaranteed results',
        'Ideal for SMBs and Entrepreneurs',
      ],
    },
    {
      title: 'Time & Material',
      icons: CgLock,
      features: [
        'Low risk',
        'High flexibility',
        'Quick start',
        'Transparency & collaboration',
        'Pay only for the work done',
      ],
    },
    {
      title: 'Dedicated Team Model',
      icons: BiUser,
      features: [
        'Low Risk',
        'Highest control',
        'Highest quality',
        'Long term commitment',
        'High level of flexibility',
        'Expert Developer',
      ],
    },
  ];

  const faqItems = [
    {
      question: 'What is the Difference Between Frontend and Backend Development?',
      answer: 'Frontend development focuses on the user interface and user experience, using HTML, CSS, and JavaScript. Backend development deals with server-side logic and databases, typically using Python, Ruby, or Node.js.',
    },
    {
      question: 'What are APIs and How are They Used in Software Development?',
      answer: 'APIs are sets of protocols that allow software components to communicate. They integrate services, access external data, and create modular applications.',
    },
    {
      question: 'How Can I Improve my Software Development Skills?',
      answer: 'To improve skills, practice coding regularly, work on projects, contribute to open-source, read technical blogs, attend workshops, and collaborate with developers.',
    },
    {
      question: 'What are the Ethical Considerations in AI Development?',
      answer: 'Ethical considerations include data privacy, bias in algorithms, transparency in AI decisions, societal impact, safety, and addressing job displacement due to automation.',
    },
  ];

  return (
    <div className='font-manrope'>
      <Helmet>
        <title>{service.title} | Solution Soul</title>
        <meta
          name="description"
          content="Learn about Solution Soul, experts in web development, app development, SEO, and digital marketing. Discover how our team helps businesses grow through innovative solutions."
        />
      </Helmet>
      <div className="mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row gap-8 px-4">
          <div className="md:w-2/3">
            <img
              src={service.image}
              alt="Web developer working"
              className="rounded-2xl w-full h-auto"
              loading="lazy"
            />
   <p className="mt-8 text-gray-800 text-lg">
  {service.description.split('. ').map((sentence, index) => (
    <span key={index} className="block pb-2"> {/* Added block and padding bottom */}
      {sentence.trim()}
      {index < service.description.split('. ').length - 1 && <br />}
    </span>
  ))}
</p>

            
          </div>
          <div className="md:w-1/3">
            <div className="bg-[#F7F8FD] p-6 rounded-xl">
              <div className="flex items-center gap-2 mb-8">
                <div className="bg-purple-100 p-2 rounded-lg">
                  <service.icon className="w-8 h-8 text-[#304f7c]" />
                </div>
                <h2 className="text-2xl md:text-xl font-bold">{service.title}</h2>
              </div>
              <ul className="space-y-6">
                {service.points.map((item, index) => (
                  <li key={index} className="flex items-center gap-2">
                    <MdKeyboardArrowRight className="w-5 h-5 text-white rounded-full bg-[#379885]" />
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
              <Link to="/contact-us" className="flex bg-[#304f7c] text-white mt-6 px-6 py-3 font-semibold rounded-full border-2 border-[#344f7c] overflow-hidden transition-transform duration-300 will-change-transform z-0 items-center  group hover:text-[#344f7c]">
                Contact Now <FaArrowRight className="ml-2 h-4 w-4 transition-transform -rotate-45 duration-300 transform group-hover:rotate-0" />
                <span className="absolute inset-0 bg-white rounded-full transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0 will-change-transform z-[-1]"></span>
              </Link>
            </div>
          </div>
        </div>

        <div className="mt-6">
          <ChooseUsSection />
          <div className="min-h-screen py-12 px-6">
            <div className="mx-auto">
              <h2 className="text-[#379885] text-xl font-semibold mb-4" data-aos="fade-up">Your Choice</h2>
              <h1 className="mb-12 text-5xl font-bold leading-tight md:text-5xl lg:text-7xl" data-aos="fade-up">
                Select engagement
                <br />
                models of your choice
              </h1>
              <section className="mx-auto px-4 py-16 md:px-6 lg:px-8 bg-white font-manrope">
                <div className="relative mx-auto max-w-7xl grid grid-cols-1 gap-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                  {models.map((serviceslist, index) => (
                    <div
                      key={index}
                      className="group bg-[#F7F8FD] rounded-3xl shadow-md border border-white overflow-hidden flex flex-col"
                      data-aos="fade-up"
                      data-aos-delay={100 * (index + 1)}
                    >
                      <div className="p-8 flex flex-col justify-between h-full">
                        <div>
                          <h2 className="text-3xl font-bold text-[#379885] mb-4">{serviceslist.title}</h2>
                          <div className="flex items-center mb-4">
                            <serviceslist.icons className="text-[#304f7c] w-7 h-7 mr-2" />
                            <h2 className="text-lg">Best Choice</h2>
                          </div>
                          <ul className="list-disc list-inside">
                            {serviceslist.features.map((feature, index) => (
                              <li key={index} className="text-gray-600 text-sm mb-2">{feature}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </div>

          <section className="px-4 py-8 bg-white">
            <div className="mx-auto max-w-7xl">
              <h2 className="text-[#379885] text-xl font-semibold mb-4" data-aos="fade-up">Frequently Asked Questions</h2>
              <div className="rounded-2xl overflow-hidden">
                {faqItems.map((faq, index) => (
                  <div key={index} className="py-5 px-6 mb-4 border border-solid border-gray-200 rounded-2xl transition-all duration-200"
                       data-aos="fade-up"
                       data-aos-delay={100 * (index + 1)}>
                    <button
                      className={`accordion-toggle flex justify-between w-full ${openIndex === index ? 'text-[#304f7c]' : 'text-gray-900'}`}
                      onClick={() => handleToggle(index)}
                    >
                      <h5>{faq.question}</h5>
                      <FaAngleDown className={`transition-transform duration-200 ${openIndex === index ? 'rotate-180' : ''}`} />
                    </button>
                    <div className={`accordion-content mt-2 ${openIndex === index ? 'block' : 'hidden'}`}>
                      <p>{faq.answer}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <div className="py-8 px-4 bg-white">
            <div className="mx-auto max-w-7xl flex flex-col md:flex-row items-center justify-between">
              <h2 className="text-[#304f7c] text-3xl font-bold mb-4 md:mb-0">Get In Touch</h2>
              <Link to="/contact-us" className="flex bg-[#304f7c] text-white px-6 py-3 rounded-full border-2 border-[#344f7c] overflow-hidden transition-transform duration-300 will-change-transform z-0 items-center group hover:text-[#344f7c] font-semibold">
                Contact Now <FaArrowRight className="ml-2 h-4 w-4 transition-transform -rotate-45 duration-300 transform group-hover:rotate-0" />
                <span className="absolute inset-0 bg-white rounded-full transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0 will-change-transform z-[-1]"></span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetailPage;
