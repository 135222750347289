import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Typed from 'typed.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
const TermAndCondition = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // This will move the user to the top of the page
      }, []);
      
  useEffect(() => {
    const typed = new Typed('.typing', {
      strings: ['Terms and Conditions'],
      typeSpeed: 20,
      loop: false,
      showCursor: false,      
    });


    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className='font-manrope'>
         <Helmet>
        <title >Terms and Conditions | Solution Soul</title>
        <meta
          name="description"
          content="Learn about Solution Soul, experts in web development, app development, SEO, and digital marketing. Discover how our team helps businesses grow through innovative solutions."
        />
      </Helmet>
      <div className="relative ">
        <div  className="w-full  h-96 bg-[#304f7c] bg-no-repeat text-white" >
        <div className="absolute inset-0 flex items-center justify-center text-center"  >
          <h2 className="text-5xl md:text-6xl lg:text-7xl font-bold leading-[3.25rem] ">
            <span className="typing"></span>
          </h2>
        </div>
      </div>
      </div>
<div>
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 ">
        <h1 className="text-lg font-bold leading-tight  mb-4 md:mb-0">
Terms and Conditions
        </h1>
        <p className="text-[15px] font-medium mb-8 mt-3">
        Please read these terms and conditions carefully before using our website or software development services. By using our website or software development services, you agree to these terms and conditions.
        </p>
    </div>
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-12">
        <h1 className="text-lg font-bold leading-tight  mb-4 md:mb-0">
        Use of Website and Services:
        </h1>
        <p className="font-medium mt-3">
        Solution Soul provides software development services to clients worldwide. Our website and services are intended for individuals and businesses who are interested in our software development services.
        </p>
    </div>

    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 ">
        <h1 className="text-lg font-bold leading-tight  mb-4 md:mb-0">
        Intellectual Property:
        </h1>
        <p className="text-[15px] font-medium mb-8 mt-3">
        All content on our website, including but not limited to text, graphics, logos, images, and software, is the property of Solution Soul or our content suppliers and is protected by copyright laws. You may not copy, distribute, or use any content on our website without our prior written consent.
        </p>
        <p className="text-[15px] font-medium mb-8 mt-3">
        Our software development services are also protected by copyright laws. We retain all intellectual property rights in our software development services, including but not limited to source code, database designs, and user interfaces. You may not modify, reproduce, or distribute our software development services without our prior written consent.
        </p>
    </div>
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 ">
        <h1 className="text-lg font-bold leading-tight  mb-4 md:mb-0">
        Disclaimer of Warranties:
        </h1>
        <p className="text-[15px] font-medium mb-8 mt-3">
        Our software development services are provided “as is” and without warranties of any kind, whether express or implied. We do not warrant that our software development services will be error-free, uninterrupted, or free from viruses or other harmful components. We disclaim all warranties, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.
        </p>
       
    </div>
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 ">
        <h1 className="text-lg font-bold leading-tight  mb-4 md:mb-0">
        Limitation of Liability:
        </h1>
        <p className="text-[15px] font-medium mb-8 mt-3">
        In no event shall Solution Soul be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with our website or software development services, including but not limited to lost profits, loss of data, or interruption of business. Our total liability to you shall not exceed the amount paid by you for our software development services.
        </p>
       
    </div>
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 ">
        <h1 className="text-lg font-bold leading-tight  mb-4 md:mb-0">
        Indemnification:
        </h1>
        <p className="text-[15px] font-medium mb-8 mt-3">
      
You agree to indemnify, defend, and hold harmless Solution Soul and its officers, directors, employees, and agents from and against any claims, damages, costs, and expenses, including but not limited to attorney fees, arising out of or in connection with your use of our website or software development services.
        </p>
       
    </div>
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 ">
        <h1 className="text-lg font-bold leading-tight  mb-4 md:mb-0">
        Governing Law:
        </h1>
        <p className="text-[15px] font-medium mb-8 mt-3">
        These terms and conditions shall be governed by and construed in accordance with the laws of Pakistan, without giving effect to any principles of conflicts of law.
        </p>
       
    </div>
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 ">
        <h1 className="text-lg font-bold leading-tight  mb-4 md:mb-0">
        Updates to Terms and Conditions:
        </h1>
        <p className="text-[15px] font-medium mb-8 mt-3">
        We may update these terms and conditions from time to time. We will notify you of any changes by posting the updated terms and conditions on our website. We encourage you to review these terms and conditions periodically.
        </p>
       
    </div>
    <div className="max-w-6xl mx-auto px-4  sm:px-6 lg:px-8 mt-12 ">
        <h1 className="text-lg font-bold leading-tight  mb-4 md:mb-0">
        Contact Us:
        </h1>
        <p className="text-[15px] font-medium mb-8 mt-3">
        If you have any questions or concerns about our privacy policy, please contact us at
        <Link to="mailto:info@solutionsoul.com" className='text-[#304f7c] font-semibold mx-1'>info@solutionsoul.com</Link> We’re happy to answer any questions you may have and ensure that your personal information is protected.
        </p>
       
    </div>
    </div>
    </div>
  );
};

export default TermAndCondition;
