import React, { useEffect } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { servicelistdata } from '../components/assets/servicelistdata';



const ServiceSection=()=> {
  const formatServiceId = (title) => title.toLowerCase().replace(/\s+/g, '-');
  useEffect(() => {
    AOS.init({ duration: 600, once: true });
  }, []);

  const getFirst40Words = (text) => {
    const words = text.split(' '); // Split the description into words
    if (words.length > 23) {
      return words.slice(0, 23).join(' ')  // Return first 40 words with ellipsis
    }
    return text; // If less than 40 words, return the original text
  };

  return (
    <section className=" mx-auto px-4 py-16 md:px-6 lg:px-8 bg-[#F7F8FD] font-manrope" >
      <div className="mb-4" data-aos="fade-up">
        <h2 className="text-[#379885] text-lg font-semibold">Our Services</h2>
      </div>
      <div className="flex flex-col md:flex-row md:items-center md:justify-between ms-auto mb-12" data-aos="fade-up">
  <h1 className="text-5xl font-bold leading-tight md:text-6xl lg:text-7xl mb-4 md:mb-0">
    What we can offer today
  </h1>
  <Link
    to="/our-services"
    className="w-full md:w-auto mt-4 md:mt-0 flex bg-[#304f7c] text-white px-6 py-3 font-semibold rounded-full border-2 border-[#344f7c] overflow-hidden transition-transform duration-300 will-change-transform z-0 items-center justify-center group hover:text-[#344f7c]"
  >
    View All Services
    <FaArrowRight className="ml-2 h-4 w-5 transition-transform -rotate-45 duration-300 transform group-hover:rotate-0" />
    <span className="absolute inset-0 bg-white rounded-full transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0 will-change-transform z-[-1]"></span>
  </Link>
</div>

      <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
      {servicelistdata.map((service, index) => (
  <div
    key={index}
    className="group bg-white rounded-3xl shadow-md border border-white overflow-hidden flex flex-col"
    data-aos="fade-up"
    data-aos-delay={100 * (index + 1)} // Adjust delay based on the index
  >
    <div className="flex flex-1 flex-col p-5">
      <div className="flex justify-between items-center border-b px-2 py-5">
        <h3 className="text-3xl font-semibold w-1/2">{service.title}</h3>
        <Link
          to={`/services/${formatServiceId(service.title)}`}
          className="w-14 h-14 flex items-center justify-center bg-[#304f7c] text-white rounded-full border border-[#344f7c] transition-transform duration-300 group-hover:text-white relative font-semibold overflow-hidden will-change-transform z-0 group"
        >
          <FaArrowRight className="transition-transform -rotate-45 duration-300 transform group-hover:rotate-0 text-2xl" />
          <span className="absolute inset-0 bg-[#304f7c] rounded-full transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0 will-change-transform z-[-1]"></span>
        </Link>
      </div>
      <p className="text-gray-500 px-2 py-4 ">  {getFirst40Words(service.description)}</p>
      <Link to={`/services/${formatServiceId(service.title)}`}>
      <img
        src={service.image}
        alt={service.title}
        loading="lazy"
        className="w-full object-cover rounded-2xl transition-transform duration-300 group-hover:scale-105"
      />
      </Link>
    </div>
  </div>
))}

      </div>
    </section>
  );
}
export default ServiceSection;
