import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Typed from 'typed.js';
import AboutUsSection from '../../pages/AboutUsSection';
import ChooseUsSection from '../../pages/ChooseUsSection';
import TeamExpert from '../../pages/TeamExpert';
import Marquee from 'react-fast-marquee';
import Testimonial from '../../pages/Testimonial';

const AboutUs = () => {
  const [services, setServices] = useState([
    'Mobile App Development',
    'Web Development',
    'Graphic Design Services',
    'Custom Software Development',
    'Digital Marketing',
    'SEO Optimization'
  ]);

  useEffect(() => {
    AOS.init({ duration: 600, once: true });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0); // This will move the user to the top of the page
  }, []);
  useEffect(() => {
    const typed = new Typed('.typing', {
      strings: ['About Us'],
      typeSpeed: 30,
      loop: false,
      showCursor: false,      
    });
    
    return () => {
      typed.destroy();
    };
  }, []);
  return (
    <div className='font-manrope'>
      
      <Helmet>
        <title >About Us | Solution Soul</title>
        <meta
          name="description"
          content="Learn about Solution Soul, experts in web development, app development, SEO, and digital marketing. Discover how our team helps businesses grow through innovative solutions."
        />
      </Helmet>

      
      <div className="relative ">
        <div  className="w-full  h-96 bg-[#304f7c] bg-no-repeat text-white" >
        <div className="absolute inset-0 flex items-center justify-center text-center"  >
          <h2 className="text-5xl md:text-6xl lg:text-7xl font-bold leading-[3.25rem] ">
            <span className="typing"></span>
          </h2>
        </div>
      </div>
      </div>
      

      {/* About Us Section */}
      <main className="my-4">
        <AboutUsSection/>
        <ChooseUsSection/>
       
        <TeamExpert/>
      </main>
      <div className="w-full bg-[#304f7c] text-white py-4 overflow-hidden" >
      <Marquee gradient={false} speed={50}>
        {services.map((service, index) => (
          <div key={index} className="flex items-center mx-4">
            <span className="text-2xl font-bold">{service}</span>
            {index < services.length - 1 && (
              <span className="text-2xl mx-4">•</span>
            )}
          </div>
        ))}
      </Marquee>
    </div>
    <div>
      <Testimonial/>
    </div>
    </div>
  );
};

export default AboutUs;
