import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import NavBar from './components/navbar/NavBar'
import Home from './pages/Home'
import Footer from './pages/Footer'
import OurServices from './components/service/OurService'
import AboutUs from './components/aboutus/AboutUs'
import FAQs from './components/faqs/FAQs'
import PrivacyPolicy from './components/privacypolicy/PrivacyPolicy'
import TermAndCondition from './components/termandcondition/TermAndCondition'
import OurTeam from './components/ourteam/OurTeam'
import ContactUs from './components/contactus/ContactUs'
import Portfolio from './components/portfolio/Portfolio'
import PortfolioDetail from './components/portfolio/PortfolioDetail'
import ServiceDetailPage from './components/service/ServiceDetailPage'
import { servicelistdata } from './components/assets/servicelistdata'

const App = () => {

  return (
    <>
    <BrowserRouter>
    <NavBar/>
    <main className="pt-20"> 
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/about-us' element={<AboutUs/>}/>
      <Route path='/our-services' element={<OurServices/>}/>
      <Route path="/services/:serviceId" element={<ServiceDetailPage services={servicelistdata} />}/>
      <Route path='/portfolio' element={<Portfolio/>}/>
      <Route path="/portfolio/detail/:id" element={<PortfolioDetail />}/>
      
      <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
      <Route path='/terms-and-conditions' element={<TermAndCondition/>}/>
      <Route path='/our-team' element={<OurTeam/>}/>
      <Route path='/faqs' element={<FAQs/>}/>
      <Route path='/contact-us' element={<ContactUs/>}/>
      
    </Routes>
    <Footer/>
    </main>
    </BrowserRouter>
    </>
  )
}

export default App
