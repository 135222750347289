import React, { useEffect } from 'react';
import herobg from '../components/assets/hero-background.png';
import hero from '../components/assets/hero-img.jpg';
import { FaArrowRight } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const Hero = () => {
  useEffect(() => {
    AOS.init({ duration: 600, once: true });
  }, []);

  return (
    <div className="min-h-screen bg-[#f7f8fd]">
      <main
        className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4 mx-auto px-4 py-12 relative "
        style={{
          backgroundImage: `url(${herobg})`,
          backgroundPosition: 'bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover', 
        }}
      >
        <div className="flex flex-col md:justify-center items-start md:items-start  md:text-left p-4 font-manrope">
          <h2 className="text-[#379885] text-xl font-semibold mb-4" data-aos="fade-up">About Our Company</h2>
          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            <p data-aos="fade-right">Web Design, SEO &</p>
            <p data-aos="fade-right">Internet Marketing For</p>
            <p className="text-[#379885]" data-aos="fade-right" data-aos-delay="100">Your Business</p>
          </h1>
          <p className="text-gray-600 mb-8" data-aos="fade-up" data-aos-delay="100">
          Welcome to Solution Soul Empowering Your Vision
         with Innovative IT Solutions. Your One-Stop for 
         All Your Software Development Needs.
         At Solution Soul, we pride ourselves on offering a comprehensive range of digital solutions.
          </p>
          <div className="flex flex-col sm:flex-row justify-center md:justify-start space-y-4 sm:space-y-0 sm:space-x-4" data-aos="fade-up"  data-aos-delay="50">
        
            <Link to="/about-us" className=" flex bg-[#304f7c] text-white px-6 py-3 font-semibold rounded-full border-2 border-[#344f7c] overflow-hidden transition-transform duration-300 will-change-transform z-0 items-center justify-center group hover:text-[#344f7c]">
            Why Solution Soul <FaArrowRight className="ml-2 h-4 w-5 transition-transform -rotate-45 duration-300 transform group-hover:rotate-0" />
              <span  className="absolute inset-0 bg-white rounded-full transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0 will-change-transform z-[-1]"></span>
            </Link>
            
              </div>
        </div>

        <div className="flex justify-center items-center">
          <img
            src={hero}
            data-aos="fade-right"
            alt="Team working together"
            loading="lazy"
            className="rounded-3xl shadow-lg w-full max-w-full sm:max-w-full md:max-w-lg lg:max-w-lg"
          />
        </div>
      </main>
    </div>
  );
};

export default Hero;
