import React, { useEffect } from 'react';
import service1 from "../components/assets/works-img-1.jpg";
import service2 from "../components/assets/works-img-2.jpg";
import service3 from "../components/assets/works-img-3.jpg";
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { HiMiniArrowTopRightOnSquare } from 'react-icons/hi2';

const services = [
  {
    title: 'Operation Atlas',
    description: 'Create a professional online presence with our tailored website development solutions designed to showcase your brand and engage your audience effectively.',
    image: service1,
    tags: ["React", "Next.js", "Tailwind CSS"],
  },
  {
    title: 'Quantum Quest',
    description: 'Transform your ideas into powerful mobile applications with our expertise in developing intuitive and feature-rich apps for both Android and iOS platforms.',
    image: service2,
    tags: ["React", "Next.js", "Tailwind CSS"],
  },
  {
    title: 'Blue Horizon Initiative',
    description: 'Boost your online visibility and drive organic traffic to your website with our comprehensive SEO services, tailored to improve your search engine rankings.',
    image: service3,
    tags: ["React", "Next.js", "Tailwind CSS"],
  },

];

const OurWork=()=> {
  useEffect(() => {
    AOS.init({ duration: 600, once: true });
  }, []);

  return (
    <section className=" mx-auto px-4 py-16 md:px-6 lg:px-8  font-manrope" >
      <div className="mb-4" data-aos="fade-up">
        <h2 className="text-[#379885] text-lg font-semibold">Our Works</h2>
      </div>
      <div className="flex flex-col md:flex-row md:items-center md:justify-between ms-auto mb-12" data-aos="fade-up">
        <h1 className="text-5xl font-bold leading-tight md:text-4xl lg:text-6xl mb-4 md:mb-0">
        Excellence from concept to completion
        </h1>
        <Link to="/portfolio" className=" flex bg-[#304f7c] text-white px-6 py-3 font-semibold rounded-full border-2 border-[#344f7c] overflow-hidden transition-transform duration-300 will-change-transform z-0 items-center justify-center group hover:text-[#344f7c]">
          View All Portfolio <FaArrowRight className="ml-2 h-4 w-5 transition-transform -rotate-45 duration-300 transform group-hover:rotate-0" />
              <span  className="absolute inset-0 bg-white rounded-full transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0 will-change-transform z-[-1]"></span>
            </Link>
      </div>
     <div className="relative  z-10 grid grid-cols-1 gap-14 pt-14 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
{services.map((portfolio, index) => (
  <div key={index} className="bg-[#F7F8FD] rounded-lg p-2 border shadow-md overflow-hidden relative group"  data-aos="fade-up"
  data-aos-delay={100 * (index + 1)}>
    <div className="rounded-lg shadow-md border border-white overflow-hidden flex flex-col">
      <div className="relative overflow-hidden">
        <img
          src={portfolio.image}
          alt={portfolio.title}
          loading="lazy"
          className="w-full h-full object-cover object-center transition-transform duration-300 transform group-hover:scale-105"
        />
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 opacity-0 group-hover:opacity-100 transition-opacity duration-150">
          <Link
            to="/portfolio"
            className=" bg-[#304f7c] text-white font-semibold py-2 px-10 flex   rounded hover:bg-gray-200 hover:text-[#304f7c] transition-colors duration-150"
          >
            <HiMiniArrowTopRightOnSquare  className='mt-1 mx-1' size={20} />
            <span className=" text-lg font-semibold">View Details</span>
          </Link>
        </div>
      </div>
    </div>
    <div className="py-3 ">
      <h2 className="text-xl font-semibold py-3 px-2">{portfolio.title}</h2>
      
      <div className="flex flex-wrap gap-2 py-3 px-1">
        {portfolio.tags.map((tag) => (
          <span key={tag} className="bg-gray-200 text-gray-700 text-sm px-2 py-1 rounded-full">
            {tag}
          </span>
        ))}
      </div>
    </div>
  </div>
))}


            </div>
    </section>
  );
}
export default OurWork;
