import React, { useEffect } from 'react'
import { FaStar } from 'react-icons/fa';
import Slider from 'react-slick'
import AOS from 'aos';
import 'aos/dist/aos.css';
import author1 from "../components/assets/author-1.jpg"
import author2 from "../components/assets/author-2.jpg"
import author3 from "../components/assets/author-3.jpg"
import author4 from "../components/assets/author-4.jpg"
import author5 from "../components/assets/author-5.webp"
  

const testimonialList=[
 

  {
    id: 1,
    name: 'John Doe',
    image: author1,
    text: 'We approached Solution Soul to build a fitness tracking app, and they exceeded our expectations. The user interface is seamless, and the app runs smoothly on all devices.',
  },
  {
    id: 2,
    name: 'Sarah Doe',
    image: author2,
    text: "The team at Solution Soul developed a fast-food delivery app for us, and it has been a huge success. The process was smooth, and the end product is high-quality.",
  },
  {
    id: 3,
    name: 'Emily Davis',
   image:author3,
    text: "We hired Solution Soul to build our music streaming platform. The team was incredibly professional, and they delivered a robust, high-performance site on time.",
  },
  {
    id: 4,
    name: 'Jane Smith',
   image:author4,
    text: "Solution Soul helped us develop an educational app, and we are extremely happy with the result. The app is easy to use and has all the features we needed.",
  },
  {
    id: 5,
    name: 'Michael Brown',
   image:author5,
    text: "Solution Soul developed a secure, feature-rich website for our financial services business. Their attention to detail and commitment to meeting deadlines was outstanding.",
  },
  
]

const Testimonial = () => {
  useEffect(() => {
    AOS.init({ duration: 600, once: true });
  }, []);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    
    cssEase: 'linear',
    pauseOnHover: true,
    pauseOnFocus: true,
    responsive:[
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide:2,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  return (
    <div className='mx-auto px-4 py-16 md:px-6 lg:px-8 font-manrope '>
      <div className="mb-4" data-aos="fade-up">
        <h2 className="text-[#379885] text-lg font-semibold">Client Testimonials</h2>
      </div>
      <div className=" mb-12" data-aos="fade-up">
        <h1 className="text-5xl font-bold leading-tight md:text-4xl lg:text-6xl mb-4 md:mb-0">
        Our customers love us.
        </h1>
        
      </div>
            <div>
<Slider {...settings}>
{testimonialList.map((data,index)=>(
  <div className='my-6' data-aos="fade-up"
  data-aos-delay={50 * (index + 1)} > 
    <div key={data.id} 
  className='flex flex-col gap-2 shadow-lg py-8 px-6 mx-6 rounded-3xl  relative bg-[#f7f8fd]'>
    <div className='mb-4 flex '>
      <img src={data.image} alt='testimonial'
      loading="lazy" 
      className='rounded-full w-16 h-16'/>
      <h1 className='text-xl font-bold  mx-3 my-4'>{data.name}</h1>
         </div>
         <div className="flex justify-start mb-4">
                {[...Array(5)].map((_, i) => (
                  <FaStar key={i} className=" text-orange-400 fill-current mx-1" />
                ))}
              </div>
    <div className='flex flex-col items-center gap-4'>
     
      <p className='text-xs text-gray-500'>{data.text}</p>
      </div>
    <p className='text-[#304f7c] text-9xl font-serif absolute -top-4 right-0'>
    ,,
    </p>
  </div>
     </div>
  
))}
</Slider>
            </div>
     
      
    </div>
  )
}

export default Testimonial
