import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaFacebookF } from 'react-icons/fa';
import { LiaLinkedin } from 'react-icons/lia';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

const OurTeam = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      setLoading(true);  // Ensure loading is set to true before the request
      try {
        const response = await axios.get('https://server.solutionsoul.com/auth/team-member/detail', {
          headers: {
            'Cache-Control': 'no-cache'  // Cache-busting to ensure fresh data
          }
        });
        setTeamMembers(response.data.teamMembers);
        setError(null); // Reset error state in case of successful fetch
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamMembers();
  }, []);  // Empty dependency array to run once on initial mount

  useEffect(() => {
    AOS.init({ duration: 600, once: true });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0); // This will move the user to the top of the page
  }, []);

  if (loading) {
    return (
      <div className='font-manrope pt-20 flex items-center justify-center h-screen'>
        <div className="flex items-center">
          <div className="w-5 h-5 border-4 border-t-transparent border-[#304f7c] rounded-full animate-spin mr-2"></div>
          <span>Loading...</span>
        </div>
      </div>
    ); // Display loader while loading
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

 

  

  const baseUrl = 'https://server.solutionsoul.com'; // Base URL for images


  return (
    <div className='font-manrope'>
       <Helmet>
        <title >Our Team | Solution Soul</title>
        <meta
          name="description"
          content="Learn about Solution Soul, experts in web development, app development, SEO, and digital marketing. Discover how our team helps businesses grow through innovative solutions."
        />
      </Helmet>
      <section className="mx-auto px-4 py-16 md:px-6 lg:px-8">
        <div className="mb-4" data-aos="fade-up">
          <h2 className="text-[#379885] text-lg font-semibold">Our Team</h2>
        </div>
        <div className="mb-12" data-aos="fade-up">
          <h1 className="text-5xl font-bold leading-tight md:text-4xl lg:text-6xl mb-4 md:mb-0">
            Meet the Expert
          </h1>
        </div>
        <div className="mx-auto max-w-7xl grid grid-cols-1 gap-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-16">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              data-aos="fade-up" data-aos-delay={50 * (index + 1)}
              className="bg-[#f7f8fd] rounded-lg shadow-lg overflow-hidden"
            
            >
              <div className="relative">
                <img
                  src={`${baseUrl}${member.image}`}
                  alt={member.name}
                  loading="lazy"
                  className="relative z-10 w-52 h-52 mx-auto mt-6 object-cover rounded-full object-top bg-white  shadow"
                />
              </div>
              <div className="p-6 text-center">
                <h2 className="text-xl font-bold">{member.name}</h2>
                <p className="text-gray-600 mb-2">{member.designation}</p>
                <p className="text-sm text-gray-500 mb-4">{member.description}</p>
                <Link
                   to={member.socialLinks.facebook.startsWith('http') 
                    ? member.socialLinks.facebook
                    : `https://${member.socialLinks.facebook}`}
                  target="_blank"                     // Opens the link in a new tab
                  rel="noopener noreferrer"           // Adds security (recommended when using target="_blank")
                 
                  aria-label={`Facebook profile of ${member.name}`}
                  className="inline-block bg-[#304f7c] p-2 rounded-full transition-colors duration-300 hover:bg-white group shadow-lg" 
                >
                  <FaFacebookF className="w-5 h-5 text-white  transition-colors duration-300 group-hover:text-[#304f7c]" />
                </Link>
                <Link
                  to={member.socialLinks.linkedin.startsWith('http') 
                    ? member.socialLinks.linkedin 
                    : `https://${member.socialLinks.linkedin}`}
                  target="_blank"                     
                  rel="noopener noreferrer"           
                  
                  aria-label={`LinkedIn profile of ${member.name}`}
                  className="inline-block bg-[#304f7c] p-2 mx-1 rounded-full transition-colors duration-300 hover:bg-white group shadow-lg" 
                >
                  <LiaLinkedin className="w-5 h-5 text-white transition-colors duration-300 group-hover:text-[#304f7c]" />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default OurTeam;


// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import { useEffect, useState } from "react";
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import { FaArrowRight, FaFacebookF } from 'react-icons/fa';
// import { LiaLinkedin } from 'react-icons/lia';

// const OurTeam = () => {
//   const [teamMembers, setTeamMembers] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchTeamMembers = async () => {
//       setLoading(true);  // Ensure loading is set to true before the request
//       try {
//         const response = await axios.get('https://server.solutionsoul.com/auth/team-member/detail', {
//           headers: {
//             'Cache-Control': 'no-cache'  // Cache-busting to ensure fresh data
//           }
//         });
//         setTeamMembers(response.data.teamMembers);
//         setError(null); // Reset error state in case of successful fetch
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchTeamMembers();
//   }, []);  // Empty dependency array to run once on initial mount

//   useEffect(() => {
//     AOS.init({ duration: 600, once: true });
//   }, []);
//   useEffect(() => {
//     window.scrollTo(0, 0); // This will move the user to the top of the page
//   }, []);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

 

  

//   const baseUrl = 'https://server.solutionsoul.com'; // Base URL for images


//   return (
//     <section className="mx-auto px-4 py-16 md:px-6 lg:px-8 font-manrope bg-[#f7f8fd]">
//       <div className="mb-4" data-aos="fade-up">
//         <h2 className="text-[#379885] text-lg font-semibold">Our Team</h2>
//       </div>
//       <div className="flex flex-col md:flex-row md:items-center md:justify-between ms-auto mb-12" data-aos="fade-up">
//   <h1 className="text-5xl font-bold leading-tight md:text-6xl lg:text-7xl mb-4 md:mb-0">
//   Meet the Expert
//   </h1>
//   <Link
//     to="/our-team"
//     className="w-full md:w-auto mt-4 md:mt-0 flex bg-[#304f7c] text-white px-6 py-3 font-semibold rounded-full border-2 border-[#344f7c] overflow-hidden transition-transform duration-300 will-change-transform z-0 items-center justify-center group hover:text-[#344f7c]"
//   >
//     Our Team
//     <FaArrowRight className="ml-2 h-4 w-5 transition-transform -rotate-45 duration-300 transform group-hover:rotate-0" />
//     <span className="absolute inset-0 bg-white rounded-full transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0 will-change-transform z-[-1]"></span>
//   </Link>
// </div>
//       {/* <div className="grid grid-cols-1 min-[500px]:grid-cols-2 md:grid-cols-6 lg:grid-cols-5 gap-8 max-w-xl mx-auto md:max-w-3xl lg:max-w-full">
//         {teamMembers.slice(0, 5).map((member, index) => (
//            <div 
//            key={index}
//            data-aos="fade-up" data-aos-delay={50 * (index + 1)}
//            className="block group min-[500px]:col-span-2 mx-auto md:col-span-2 lg:col-span-1 ">
//              <div className="relative mb-6">
//                <img  src={`${baseUrl}${member.image}`}
//                   alt={member.name} className="w-44 h-44 rounded-full bg-white shadow-md mx-auto transition-all duration-500 object-cover border border-solid border-transparent group-hover:border-[#304f7c] object-top" />
//              </div>
//              <h4 className="text-xl font-semibold text-gray-900 mb-2 capitalize text-center transition-all duration-500 group-hover:text-[#304f7c]">
//                {member.name}</h4>
//              <span className="text-gray-500 text-center block transition-all duration-500 group-hover:text-gray-900">
//                {member.designation}</span>
//            </div>
         
//         ))}
//       </div> */}


// <div className="mx-auto max-w-7xl grid grid-cols-1 gap-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-16">
//       {teamMembers.map((member, index) => (
//             <div
//               key={index}
//               data-aos="fade-up" data-aos-delay={50 * (index + 1)}
//               className="bg-[#f7f8fd] rounded-lg shadow-lg overflow-hidden"
            
//             >
//               <div className="relative">
//                 <img
//                   src={`${baseUrl}${member.image}`}
//                   alt={member.name}
//                   className="relative z-10 w-52 h-52 mx-auto mt-6 object-cover rounded-full object-top bg-white  shadow"
//                 />
//               </div>
//               <div className="p-6 text-center">
//                 <h2 className="text-xl font-bold">{member.name}</h2>
//                 <p className="text-gray-600 mb-2">{member.designation}</p>
//                 <p className="text-sm text-gray-500 mb-4">{member.description}</p>
//                 <Link
//                    to={member.socialLinks.facebook.startsWith('http') 
//                     ? member.socialLinks.facebook
//                     : `https://${member.socialLinks.facebook}`}
//                   target="_blank"                     // Opens the link in a new tab
//                   rel="noopener noreferrer"           // Adds security (recommended when using target="_blank")
                 
//                   aria-label={`Facebook profile of ${member.name}`}
//                   className="inline-block bg-[#304f7c] p-2 rounded-full transition-colors duration-300 hover:bg-white group shadow-lg" 
//                 >
//                   <FaFacebookF className="w-5 h-5 text-white  transition-colors duration-300 group-hover:text-[#304f7c]" />
//                 </Link>
//                 <Link
//                   to={member.socialLinks.linkedin.startsWith('http') 
//                     ? member.socialLinks.linkedin 
//                     : `https://${member.socialLinks.linkedin}`}
//                   target="_blank"                     
//                   rel="noopener noreferrer"           
                  
//                   aria-label={`LinkedIn profile of ${member.name}`}
//                   className="inline-block bg-[#304f7c] p-2 mx-1 rounded-full transition-colors duration-300 hover:bg-white group shadow-lg" 
//                 >
//                   <LiaLinkedin className="w-5 h-5 text-white transition-colors duration-300 group-hover:text-[#304f7c]" />
//                 </Link>
//               </div>
//             </div>
//           ))}
//         </div>
//     </section>
//   );
// };

// export default OurTeam;
