import React, { useEffect } from 'react';

import Hero from './Hero';
import ServiceSection from './ServiceSection';
import ChooseUsSection from './ChooseUsSection';
import OurWork from './OurWork';
import Testimonial from './Testimonial';
import AboutUsSection from './AboutUsSection';
import { Helmet } from 'react-helmet';




const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // This will move the user to the top of the page
  }, []);
  return (
    <div>
         <Helmet>
        <title>Home | Solution Soul</title>
        <meta
          name="description"
          content="Welcome to Solution Soul. We offer top-quality services like web development, app development SEO, and digital marketing to help your business succeed."
        />
      </Helmet>
      <div>
       <Hero/>
       <AboutUsSection/>
      <ServiceSection/>
      <OurWork/>
      <ChooseUsSection/>
      <Testimonial/>
     
      </div>
      
  

    </div>
  );
}

export default Home;
