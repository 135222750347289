// // import React, { useEffect } from 'react';
// // import { Helmet } from 'react-helmet';
// // import aboutUsBanner from '../assets/portfolio_bg.jpg';
// // import AOS from 'aos';
// // import 'aos/dist/aos.css';
// // import Typed from 'typed.js';
// // import { BiCalendar, BiGlobe, BiMapPin, BiUser } from "react-icons/bi";
// // import post1 from "../assets/post-1.jpg";
// // import post2 from "../assets/post-2.jpg";
// // import post3 from "../assets/post-1.jpg"; // Fixed the duplication
// // import { MdKeyboardArrowRight } from "react-icons/md";
// // import { BsYoutube } from "react-icons/bs";
// // import { Link, useParams } from "react-router-dom";
// // import { portfoliolist } from '../assets/portfoliolist';
// // import img from "../assets/ottawa/main.jpg"

// // const PortfolioDetail = () => {
// //   const { portfolioId } = useParams();
// //   const formatPortfolioId = (title) => title.toLowerCase().replace(/\s+/g, '-');

// //   const portfolio = portfoliolist.find(port => formatPortfolioId(port.title) === portfolioId);

// //   const posts = [
// //     { image: post1 },
// //     { image: post2 },
// //     { image: post3 },
// //   ];

// //   useEffect(() => {
// //     AOS.init({ duration: 600, once: true });
// //   }, []);

// //   useEffect(() => {
// //     window.scrollTo(0, 0); // Move the user to the top of the page
// //   }, []);

// //   if (!portfolio) {
// //     return (
// //       <div className="flex items-center justify-center h-screen">
// //         <p className="text-2xl font-bold text-gray-600">Portfolio not found!</p>
// //       </div>
// //     );
// //   }
  
// //   return (
// //     <div className="font-manrope">
      
// //       <Helmet>
// //   <title>{portfolio.title} | Solution Soul</title>
// //   <meta
// //     name="description"
// //     content={`Explore the ${portfolio.category} project, "${portfolio.title}". Learn how Solution Soul delivered a top-tier project to meet business objectives.`}
// //   />
// // </Helmet>


// //       {/* Content Section */}
// //       <div className="mx-auto px-4 py-8 md:px-20 font-manrope max-w-7xl">
// //         {/* Banner Image Section */}
// //         <div className="flex flex-col lg:flex-row gap-4 mb-8 px-4 lg:px-10">
          
// //           <div className="lg:w-1/2">
// //             <img
// //               src={img}
// //               alt={portfolio.title}
// //               className="w-full h-64 sm:h-80 md:h-96 rounded-3xl shadow-lg"
// //             />
// //           </div>
// //           <div className="lg:w-1/2 space-y-6 py-4">
// //             <div className="flex gap-2">
// //               <h2 className="text-xl font-bold">Project Title:</h2>
// //               <p className="text-gray-500 text-lg">{portfolio.title}</p>
// //             </div>
// //             <div className="flex gap-2">
// //               <p className="text-xl font-bold">Category:</p>
// //               <p className="text-gray-500 text-lg">{portfolio.category}</p>
// //             </div>
// //             <div >
// //               <p className="text-xl font-bold">Description:</p>
// //               <p className="text-gray-500 text-lg"> {portfolio.description}</p>
// //             </div>
// //           </div>
// //         </div>

// //         <div className="md:flex md:space-x-8">
// //           {/* Project Details Sidebar */}
// //           <div className="bg-slate-100 rounded-lg px-4 py-6 mb-8 md:mb-0 md:w-1/5">
// //             <div className="text-center border-b-2 pb-6">
// //               <BiCalendar className="w-16 h-16 text-[#304f7c] mx-auto" />
// //               <span className="text-gray-500 text-xl">Date</span>
// //               <p className="font-semibold text-lg">10 April 2024</p>
// //             </div>
// //             <div className="text-center border-b-2 py-6">
// //               <BiUser className="w-16 h-16 text-[#304f7c] mx-auto" />
// //               <span className="text-gray-500 text-xl">Client</span>
// //               <p className="font-semibold text-lg">Lorem Ipsum</p>
// //             </div>
// //             <div className="text-center border-b-2 py-6">
// //               <Link to="#" target="_blank">
// //                 <BiGlobe className="w-16 h-16 text-[#304f7c] mx-auto" />
// //                 <span className="text-gray-500 text-xl">Website</span>
// //               </Link>
// //             </div>
// //             <div className="text-center py-6 border-b-2">
// //               <Link to="#" target="_blank">
// //                 <BsYoutube className="w-16 h-16 text-[#304f7c] mx-auto" />
// //                 <span className="text-gray-500 text-xl">YouTube</span>
// //               </Link>
// //             </div>
// //             <div className="text-center py-6">
// //               <BiMapPin className="w-16 h-16 text-[#304f7c] mx-auto" />
// //               <span className="text-gray-500 text-xl">Location</span>
// //               <p className="font-semibold text-lg">New York, USA</p>
// //             </div>
// //           </div>

// //           {/* Project Overview Section */}
// //           <div className="md:w-4/5 space-y-6">
// //             <h1 className="text-3xl font-bold mb-4">Project Overview</h1>
// //             <p className="text-gray-600">
// //               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
// //               industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
// //               scrambled it to make a type specimen book...
// //             </p>
// //             <p className="text-gray-600">
// //             Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?
// //             </p>

// //             {/* Project Summary Section */}
// //             <h2 className="text-2xl font-bold mt-8 mb-4">Project Summary:</h2>
// //             <p className='text-gray-600'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
// //             <ul className="space-y-2 text-gray-600">
// //               <li className="flex gap-2">
// //                 <MdKeyboardArrowRight className="w-4 h-4 mt-1 text-white rounded-full bg-[#379885]" />
// //                 <span className="font-semibold">Brief summary of the project's purpose and goals.</span>
// //               </li>
// //               <li className="flex gap-2">
// //                 <MdKeyboardArrowRight className="w-4 h-4 mt-1 text-white rounded-full bg-[#379885]" />
// //                 <span className="font-semibold">Defect tracking and resolution process.</span>
// //               </li>
// //               <li className="flex gap-2">
// //                 <MdKeyboardArrowRight className="w-4 h-4 mt-1 text-white rounded-full bg-[#379885]" />
// //                 <span className="font-semibold">Approach or methodology chosen for the development process.</span>
// //               </li>
// //               <li className="flex gap-2">
// //                 <MdKeyboardArrowRight className="w-4 h-4 mt-1 text-white rounded-full bg-[#379885]" />
// //                 <span className="font-semibold">Testing and quality standards met.</span>
// //               </li>
// //             </ul>
// //           </div>
// //         </div>

// //         {/* Project Images Section */}
// //         <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
// //           {posts.map((post, index) => (
// //             <img
// //               key={index}
// //               src={post.image}
// //               alt={`Office environment ${index + 1}`}
// //               className="w-full h-48 object-cover rounded-lg shadow-md"
// //             />
// //           ))}
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default PortfolioDetail;






// import React, { useState } from 'react';
// import { BiCheckCircle, BiChevronLeft, BiCode, BiPhone } from 'react-icons/bi';
// import { BsLayers } from 'react-icons/bs';
// import { FaExternalLinkAlt } from 'react-icons/fa';
// import { Link } from 'react-router-dom';
// import banner from '../assets/works-img-1.jpg';
// import mobile from '../assets/ottawa/main.jpg';
// import mobile_1 from '../assets/ottawa/pic_1.jpg';
// import mobile_2 from '../assets/ottawa/pic_2.jpg';
// import mobile_3 from '../assets/ottawa/pic_3.jpg';
// import mobile_4 from '../assets/ottawa/pic_4.jpg';
// import mobile_5 from '../assets/ottawa/pic_5.jpg';
// import mobile_6 from '../assets/ottawa/pic_6.jpg';
// import mobile_7 from '../assets/ottawa/pic_7.jpg';
// import mobile_8 from '../assets/ottawa/pic_8.jpg';
// import mobile_9 from '../assets/ottawa/pic_9.jpg';
// import mobile_10 from '../assets/ottawa/pic_10.jpg';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination, Navigation } from 'swiper/modules';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// import { GrStatusGood } from 'react-icons/gr';


// const PortfolioDetail = () => {
//   return (
//     <div className='font-manrope px-4'>
//       <div className="container mx-auto px-4 py-8 ">
//         <Link to="/portfolio" className="inline-flex items-center text-sm   mb-6">
//           <BiChevronLeft className="mr-2 h-4 w-4" />
//           Back to Portfolio
//         </Link>

//         <h1 className="text-4xl font-bold mb-6">HealthTrack: Fitness & Wellness App</h1>

//         <div className="grid gap-8 md:grid-cols-2">
//           <div>
//             <img
//               src={banner}
//               alt="HealthTrack App main screen"
//               width={600}
//               height={400}
//               className="rounded-lg object-cover w-full border-2"
//             />
//           </div>

//           <div>
//             <h2 className="text-2xl font-semibold mb-4">HealthTrack: Fitness & Wellness App</h2>
//             <p className=" mb-6">
//               HealthTrack is a comprehensive fitness and wellness mobile application designed to help users monitor their
//               health, track workouts, and achieve their fitness goals. The app provides personalized workout plans,
//               nutrition tracking, and integration with wearable devices.HealthTrack is a comprehensive fitness and wellness mobile application designed to help users monitor their
//               health, track workouts, and achieve their fitness goals. The app provides personalized workout plans,
//               nutrition tracking, and integration with wearable devices.
//             </p>

//             <h3 className="text-xl font-semibold mb-2">Technologies Used</h3>
//             <div className="flex flex-wrap gap-2 mb-6">
//               {['React Native', 'TypeScript', 'Redux', 'Node.js', 'MongoDB', 'AWS'].map((tech) => (
//                 <span key={tech} className="bg-gray-200 text-gray-800 py-1 px-3 rounded-lg text-sm font-medium">
//                   {tech}
//                 </span>
//               ))}
//             </div>

//             <div className="flex flex-col sm:flex-row justify-center md:justify-start space-y-4 sm:space-y-0 sm:space-x-4" >
        
//         <Link to="/about-us" className=" flex bg-[#304f7c] text-white px-6 py-3 font-semibold rounded-full border-2 border-[#344f7c] overflow-hidden transition-transform duration-300 will-change-transform z-0 items-center justify-center group hover:text-[#344f7c]">
//          <FaExternalLinkAlt className="ml-2 h-4 w-5 transition-transform  duration-300 transform " />
//          Watch Video
//           <span  className="absolute inset-0 bg-white rounded-full transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0 will-change-transform z-[-1]"></span>
//         </Link>
        
//           </div>
//           </div>
//         </div>

//         {/* Tab Selection */}
//         <div className="mt-12">
//          <h2 className="text-xl font-semibold mb-4">Project Overview</h2>
//          <p className=" mb-6">
//               HealthTrack is a comprehensive fitness and wellness mobile application designed to help users monitor their
//               health, track workouts, and achieve their fitness goals. The app provides personalized workout plans,
//               nutrition tracking, and integration with wearable devices.HealthTrack is a comprehensive fitness and wellness mobile application designed to help users monitor their
//               health, track workouts, and achieve their fitness goals. The app provides personalized workout plans,
//               nutrition tracking, and integration with wearable devices.
//             </p>
//         </div>
//         <div className="pt-1 space-y-4">
//   <h3 className="text-xl font-semibold mb-2">Features</h3>
  
//   <div className="flex items-start gap-2">
//     <GrStatusGood size={20} />
//     <div>
//       <span className="font-bold">Progress Analytics:</span>
//       <span className="ml-1">
//         Monitor your health and track progress over time with detailed analytics.HealthTrack is a comprehensive fitness and wellness mobile application designed to help users monitor their
//         health, track workouts, and achieve their fitness goals.
//       </span>
//     </div>
//   </div>
  
//   <div className="flex items-start gap-2">
//     <GrStatusGood size={20} />
//     <div>
//       <span className="font-bold">Personalized Workout Plans:</span>
//       <span className="ml-1">
//         Get customized workout plans tailored to your fitness goals and needs.
//       </span>
//     </div>
//   </div>
  
//   <div className="flex items-start gap-2">
//     <GrStatusGood size={20} />
//     <div>
//       <span className="font-bold">Nutrition Tracking:</span>
//       <span className="ml-1">
//         Track your daily nutrition intake to stay aligned with your wellness goals.
//       </span>
//     </div>
//   </div>
// </div>


//       </div>

//       <div className="mt-12">
//       <h2 className="text-2xl font-semibold mb-6">App Screenshots</h2>
//       <Swiper
//         modules={[Pagination, Navigation]}
//         spaceBetween={10}
//         slidesPerView={1}
//         navigation
//         pagination={{ clickable: true }}
//         breakpoints={{
//           640: { slidesPerView: 2 },
//           768: { slidesPerView: 3 },
//           1024: { slidesPerView: 5 },
//         }}
//       >
//         {[
//           { src: mobile_2, alt: 'Progress analytics screen' },
//           { src: mobile_1, alt: 'Nutrition tracking screen' },
//           { src: mobile, alt: 'Workout tracking screen' },
//           { src: mobile_3, alt: 'Social features screen' },
//           { src: mobile_4, alt: 'Social features screen' },
//           { src: mobile_5, alt: 'Social features screen' },
//           { src: mobile_6, alt: 'Social features screen' },
//           { src: mobile_7, alt: 'Social features screen' },
//           { src: mobile_8, alt: 'Social features screen' },
//           { src: mobile_9, alt: 'Social features screen' },
//           { src: mobile_10, alt: 'Social features screen' },
//         ].map((img, i) => (
//           <SwiperSlide key={i} className=''>
//             <div className="p-2 px-3">
//               <img src={img.src} alt={img.alt} width={300} className="rounded-lg object-cover w-full h-full" />
//             </div>
//           </SwiperSlide>
//         ))}
//       </Swiper>
//     </div>

//       {/* Contact Section */}
//       <div className="mt-12 text-center">
//         <h2 className="text-2xl font-semibold mb-4">Interested in developing a mobile app?</h2>
//         <button className="btn-primary text-lg px-6 py-2 rounded-lg">Get in Touch</button>
//       </div>
//     </div>
//   );
// };

// export default PortfolioDetail;






import React, { useEffect, useState } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { GrStatusGood } from 'react-icons/gr';
import axios from 'axios';
import { BiChevronLeft } from 'react-icons/bi';
import { Helmet } from 'react-helmet';


const PortfolioDetail = () => {
    const { id } = useParams();
    const [portfolio, setPortfolio] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    useEffect(() => {
      const fetchPortfolio = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`https://server.solutionsoul.com/auth/portfolio/detail/${id}`, {
            headers: {
              'Cache-Control': 'no-cache',
            },
          });
          console.log("Fetched Portfolio:", response.data);
          setPortfolio(response.data.portfolio); // Set the portfolio object from the response
          setError(null);
        } catch (err) {
          setError('Could not load Portfolio. Please try again later.');
        } finally {
          setLoading(false);
        }
      };
  
      fetchPortfolio();
    }, [id]);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    
  
    if (loading) return <div className='font-manrope pt-20 flex items-center justify-center h-screen'>
    <div className="flex items-center">
      <div className="w-5 h-5 border-4 border-t-transparent border-[#304f7c] rounded-full animate-spin mr-2"></div>
      <span>Loading...</span>
    </div>
  </div>

    if (error) return <p>Error: {error}</p>;
  
// Safely split and map the overviewText into items, ensuring each is defined
const overviewText = portfolio.projectoverview || '';
  
// Split the overview text by numbered points (e.g., "1.", "2.", "3."), and remove empty strings.
const overviewItems = overviewText.split(/\d+\.\s/).filter(item => item.trim() !== "");


    
  return (
    <div className='font-manrope px-4 pt-18'>
      <Helmet>
  <title>{portfolio.title} | Solution Soul</title>
  <meta name="description" content={portfolio.description} />
</Helmet>
        {portfolio && (
        <div>
      <div className=" mx-auto px-4 py-8 ">
       <Link to="/portfolio" className="inline-flex items-center text-sm   mb-6">
         <BiChevronLeft className="mr-2 h-4 w-4" />
         Back to Portfolio
       </Link>

        <h1 className="text-4xl font-bold mb-6">{portfolio.title}</h1>

        <div className="grid gap-8 md:grid-cols-2">
        {portfolio.mainImage && (
          <div>
            <img
              src={`https://server.solutionsoul.com${portfolio.mainImage}`}
              alt={`${portfolio.title} main`}
              width={600}
              height={400}
              loading="lazy"
              className="rounded-lg object-cover w-full border-2"
            />
          </div>
        )}

          <div>
            <h2 className="text-2xl font-semibold mb-4">{portfolio.title}</h2>
            <p className=" mb-6">
            {portfolio.description}
            </p>          
            <h3 className="text-xl font-semibold mb-2">Technologies Used</h3>
            <div className="flex flex-wrap gap-2 mb-6">
            {portfolio.technology && portfolio.technology.length > 0 ? (
              portfolio.technology
                .filter((tech) => !['react', 'node.js', 'mongodb'].includes(tech.toLowerCase())) // Exclude unwanted technologies
                .map((tech, index) => (
                <span key={index} className="bg-gray-200 text-gray-800 py-1 px-3 rounded-lg text-sm font-medium">
                  {tech}
                </span>
              ))
            ):(
<li>No technologies listed</li>
           )}
            </div>
            

            <div className="flex flex-col sm:flex-row justify-center md:justify-start space-y-4 sm:space-y-0 sm:space-x-4" >
        
        <Link   to={portfolio.videoLink}
    target="_blank"
    rel="noopener noreferrer"
    aria-label="YouTube Video"
    className=" flex bg-[#304f7c] text-white px-6 py-3 font-semibold rounded-full border-2 border-[#344f7c] overflow-hidden transition-transform duration-300 will-change-transform z-0 items-center justify-center group hover:text-[#344f7c]">
         <FaExternalLinkAlt className="ml-2 h-4 w-5 transition-transform  duration-300 transform " />
         Watch Video
          <span  className="absolute inset-0 bg-white rounded-full transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0 will-change-transform z-[-1]"></span>
        </Link>
        
          </div>
          </div>
        </div>

        <div className="mt-12">
    <h2 className="text-xl font-semibold mb-4">Project Overview</h2>
    <div className="mb-6">
  {overviewItems.map((item, index) => (
    <div className="flex items-start gap-2 mb-4" key={index}>
      <GrStatusGood size={15} className='text-[#379885] mt-0.5' />
      <p><strong>{item.trim().split(':')[0]}:</strong> {item.trim().split(':').slice(1).join(':')}</p>
    </div>
  ))}
</div>

  </div>

      </div>
      {portfolio.screenshots && portfolio.screenshots.length > 0 && (
      <div className="mt-12">
      <h2 className="text-2xl font-semibold mb-6">App Screenshots</h2>
      <Swiper
        modules={[Pagination, Navigation]}
        spaceBetween={10}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        breakpoints={{
          640: { slidesPerView: 2 },
          768: { slidesPerView: 3 },
          1024: { slidesPerView: 5 },
        }}
      >
       
        {portfolio.screenshots.map((screenshot, index) => (
          <SwiperSlide key={index} className=''>
            <div className="p-2 px-3">
              <img src={`https://server.solutionsoul.com${screenshot}`} alt={`Screenshot ${index + 1} of ${portfolio.title}`} width={300} className="rounded-lg object-cover w-full h-full "loading="lazy" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
      )}
    </div>
        )}
    </div>
  );
};

export default PortfolioDetail;
