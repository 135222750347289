import React, { useState } from 'react';
import logo from '../assets/SolutionSoul Web.png';
import { Link, NavLink } from 'react-router-dom';
import { FaArrowRight, FaBars, FaTimes } from 'react-icons/fa';

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="fixed top-0 left-0 w-full bg-[#f7f8fd] shadow px-4 py-4 flex items-center justify-between z-50 font-manrope">
      
      <Link to="/" className='px-7'>
        <img src={logo} alt="logo" className="h-12" />
      </Link>

      
      <nav className="hidden md:flex space-x-6">
        <NavLink
          to="/"
          exact
          className={({ isActive }) =>
            isActive
             ? "block py-2 px-3 text-[#304f7c] rounded md:bg-transparent md:text-[#304f7c] md:p-0 font-semibold"
                      : "block py-2 px-3 font-semibold text-slate-500  rounded transition duration-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#379885] md:p-0"
          }
        >
          Home
        </NavLink>
        <NavLink
          to="/about-us"
          className={({ isActive }) =>
            isActive
          ? "block py-2 px-3 text-[#304f7c] rounded md:bg-transparent md:text-[#304f7c] md:p-0 font-semibold"
          : "block py-2 px-3 font-semibold text-slate-500  rounded transition duration-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#379885] md:p-0"
}
        >
          About Us
        </NavLink>
        <NavLink
          to="/our-services"
          className={({ isActive }) =>
            isActive
          ? "block py-2 px-3 text-[#304f7c] rounded md:bg-transparent md:text-[#304f7c] md:p-0 font-semibold"
          : "block py-2 px-3 font-semibold text-slate-500  rounded transition duration-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#379885] md:p-0"
}
        >
          Our Services
        </NavLink>
        <NavLink
          to="/portfolio"
          className={({ isActive }) =>
            isActive
          ? "block py-2 px-3 text-[#304f7c] rounded md:bg-transparent md:text-[#304f7c] md:p-0 font-semibold"
          : "block py-2 px-3 font-semibold text-slate-500  rounded transition duration-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#379885] md:p-0"
}
        >
          Portfolio
        </NavLink>
        <NavLink
          to="/contact-us"
          className={({ isActive }) =>
            isActive
          ? "block py-2 px-3 text-[#304f7c] rounded md:bg-transparent md:text-[#304f7c] md:p-0 font-semibold"
          : "block py-2 px-3 font-semibold text-slate-500  rounded transition duration-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#379885] md:p-0"
}
        >
          Contact Us
        </NavLink>
      </nav>


            <Link
      to="/contact-us"
      className="relative hidden lg:flex bg-[#304f7c] text-white px-6 py-3 font-semibold rounded-full border-2 border-[#344f7c] overflow-hidden transition-transform duration-300 will-change-transform z-0 items-center justify-center group hover:text-[#344f7c] "
    >
      Get In Touch
      <FaArrowRight
        className="ml-2 h-4 w-4 transition-transform -rotate-45 duration-300 transform group-hover:rotate-0"
      />
      <span
        className="absolute inset-0 bg-white rounded-full transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0 will-change-transform z-[-1]"
      ></span>
    </Link>


      <div className="md:hidden flex items-center">
        <button onClick={toggleMenu} className="text-2xl text-[#f7f8fd] px-1 py-1 bg-[#379885] focus:outline-none">
          {menuOpen ? <FaTimes  /> : <FaBars   />}
        </button>
      </div>

      {menuOpen && (
        <nav className="absolute top-20 left-0 w-full bg-[#f7f8fd] shadow-md flex flex-col  space-y-6 py-6 z-50">
          <NavLink
            to="/"
            exact
            className={({ isActive }) =>
              isActive
            ? "block py-2 px-3 text-[#304f7c] rounded md:bg-transparent md:text-[#304f7c] md:p-0 font-semibold"
            : "block py-2 px-3 font-semibold text-slate-500  rounded transition duration-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#379885] md:p-0"
}
            onClick={toggleMenu}
          >
            Home
          </NavLink>
          <NavLink
            to="/about-us"
            className={({ isActive }) =>
              isActive
            ? "block py-2 px-3 text-[#304f7c] rounded md:bg-transparent md:text-[#304f7c] md:p-0 font-semibold"
            : "block py-2 px-3 font-semibold text-slate-500  rounded transition duration-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#379885] md:p-0"
}
            onClick={toggleMenu}
          >
            About Us
          </NavLink>
          <NavLink
            to="/our-services"
            className={({ isActive }) =>
              isActive
            ? "block py-2 px-3 text-[#304f7c] rounded md:bg-transparent md:text-[#304f7c] md:p-0 font-semibold"
            : "block py-2 px-3 font-semibold text-slate-500  rounded transition duration-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#379885] md:p-0"
}
            onClick={toggleMenu}
          >
            Our Services
          </NavLink>
         
          <NavLink
            to="/portfolio"
            className={({ isActive }) =>
              isActive
            ? "block py-2 px-3 text-[#304f7c] rounded md:bg-transparent md:text-[#304f7c] md:p-0 font-semibold"
            : "block py-2 px-3 font-semibold text-slate-500  rounded transition duration-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#379885] md:p-0"
}
            onClick={toggleMenu}
          >
            Portfolio
          </NavLink>
          <NavLink
            to="/contact-us"
            className={({ isActive }) =>
              isActive
            ? "block py-2 px-3 text-[#304f7c] rounded md:bg-transparent md:text-[#304f7c] md:p-0 font-semibold"
            : "block py-2 px-3 font-semibold text-slate-500  rounded transition duration-200 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#379885] md:p-0"
}
            onClick={toggleMenu}
          >
            Contact Us
          </NavLink>
        </nav>
      )}
    </header>
  );
};

export default NavBar;
