import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Typed from 'typed.js';
import { FaAngleDown } from 'react-icons/fa';
const FAQs = () => {
  useEffect(() => {
    AOS.init({ duration: 600, once: true });
  }, []);
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  useEffect(() => {
    window.scrollTo(0, 0); // This will move the user to the top of the page
  }, []);
  
useEffect(() => {
const typed = new Typed('.typing', {
  strings: ['Frequently Asked Questions'],
  typeSpeed: 20,
  loop: false,
  showCursor: false,      
});


return () => {
  typed.destroy();
};
}, []);

  const faqs = [
    {
      question: "What services does Solution Soul provide?",
      answer: "Solution Soul offers a wide range of digital solutions tailored to meet your needs. Our services include business website development, directories website development, app development for both Android and iOS platforms, SEO services, video editing, graphic design, data scraping, and custom software development. Whether you need to establish your online presence, enhance your digital marketing efforts, or develop custom software solutions, Solution Soul is here to help you achieve your goals."
    },
    {
      question: "How much do your services cost?",
      answer: "The cost of our services varies depending on the scope of the project and the services required. We provide customized quotes for each project based on the unique business requirements."
    },
    {
      question: "How does the development process work at Solution Soul?",
      answer: "Our development process includes a discovery phase, design phase, development phase, testing and QA phase, deployment and launch phase, and post-launch phase. During each phase, we keep our clients informed and involved to ensure that the project meets their business requirements."
    },
    {
      question: "How long does it take to complete a project?",
      answer: "The timeline for each project depends on the scope of the project and the services required. We work closely with our clients to establish a realistic timeline and keep them informed of the project's progress throughout the development process."
    },
    {
      question: "What technology stack does Solution Soul use?",
      answer: "We use a variety of technology stacks, including but not limited to, HTML, CSS, JavaScript, Flutter, React, React Native, Angular, Vue, Firebase, Node.js, Python, Ruby on Rails, Laravel, and .NET."
    },
    {
      question: "How do you ensure the security of client data?",
      answer: "We take data security seriously and use the latest security protocols and tools to ensure the security of our clients' data. We also have strict confidentiality policies in place to protect our clients' sensitive information."
    },
    {
      question: "What is your customer support policy?",
      answer: "We provide ongoing support and maintenance for our software development projects to ensure that the software is up-to-date and functioning optimally. We also have a customer support team available to address any questions or concerns our clients may have."
    },
  ];

  return (
    <section className='font-manrope'>
      <Helmet>
        <title>Frequently Asked Questions | Solution Soul</title>
        <meta
          name="description"
          content="Find answers to common questions about our web development, app development, SEO, and digital marketing services."
        />
      </Helmet>
      
      <div className="relative ">
        <div  className="w-full  h-96 bg-[#304f7c] bg-no-repeat text-white" >
        <div className="absolute inset-0 flex items-center justify-center text-center"  >
          <h2 className="text-5xl md:text-6xl lg:text-7xl font-bold leading-[3.25rem] ">
            <span className="typing"></span>
          </h2>
        </div>
      </div>
      </div>
      
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 font-semibold my-4">
        <div className="accordion-group space-y-4" > 
          {faqs.map((faq, index) => (
            <div data-aos="fade-up">
            <div
              key={index}
              className={`accordion py-5 px-6 border border-solid border-gray-200 transition-all duration-200 rounded-2xl  
                ${openIndex === index ? 'bg-[#304f7c] text-white' : 'bg-[#f7f8fd] text-gray-900'}`} 
            >
              <button
                className={`accordion-toggle group inline-flex items-center justify-between leading-8 w-full transition duration-200 text-left ${openIndex === index ? 'text-white' : 'text-gray-900'}`} 
                onClick={() => handleToggle(index)}
                aria-controls={`accordion-collapse-${index}`}
              >
                <h5>{faq.question}</h5>
                <FaAngleDown className={`transition duration-200 ${openIndex === index ? 'rotate-180 text-white' : 'text-[#304f7c]'}`}  />
                
              </button>
              <div
                id={`accordion-collapse-${index}`}
                className={`accordion-content w-full px-0 overflow-hidden transition-all duration-200 ${openIndex === index ? 'max-h-screen' : 'max-h-0'}`}
                aria-labelledby={`accordion-heading-${index}`}
              >
                <p className={`text-base leading-6 ${openIndex === index ? 'text-white' : 'text-gray-900'}`}>{faq.answer}</p> 
              </div>
            </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQs;
