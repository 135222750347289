import { BiGlobe } from "react-icons/bi";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaArrowRight, FaCheckCircle } from "react-icons/fa";
import { MdLightbulbOutline } from "react-icons/md";
import { FaHandHoldingDollar } from "react-icons/fa6";

const ChooseUsSection = () => {
  useEffect(() => {
    AOS.init({ duration: 600, once: true });
  }, []);
  const features = [
    {
      title: 'Innovation',
      description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.',
      icon: (

<MdLightbulbOutline 
className="w-12 h-12 text-[#379885]"
/>

      ),
    },
    {
      title: 'Quality-Focused',
      description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.',
      icon: (
<FaCheckCircle  className="w-12 h-12 text-[#379885]"/>
      ),
    },
    {
      title: 'Value For Money',
      description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.',
      icon: (
        <FaHandHoldingDollar className="w-12 h-12 text-[#379885]" />
      ),
    },
  ];

  const contactlink=[
    {
      name:"Youtube",
      Link:"https://www.youtube.com/@SolutionSoul1",
    },
    {
      name:"LinkedIn",
      Link:"https://www.linkedin.com/company/solution-soul",
    },
    {
      name:"Contact Us",
      Link:"/contact-us",
    }
   

  ]

  return (
    <section className="mx-auto px-4 py-16 md:px-6 lg:px-8 font-manrope bg-[#f7f8fd]">
      <div className="mb-4" data-aos="fade-up">
        <h2 className="text-[#379885] text-lg font-semibold">Why Choose Us</h2>
      </div>
      <div className=" mb-12" data-aos="fade-up">
        <h1 className="text-5xl font-bold leading-tight md:text-4xl lg:text-6xl mb-4 md:mb-0">
        Why choose us ?
        </h1>
        
      </div>
      <div className="mx-auto max-w-6xl z-10 grid grid-cols-1 gap-10  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3  mb-16">
        {features.map((item, index) => (
          <div key={index} 
          data-aos="fade-up"
    data-aos-delay={50 * (index + 1)} 
          className="bg-white rounded-3xl  p-8">
            <div className="mb-4">{item.icon}</div>
            <h3 className="text-2xl font-bold mb-2">{item.title}</h3>
            <p className="text-gray-600">{item.description}</p>
          </div>
        ))}
      </div>
      <div className="bg-white rounded-3xl px-10 py-8 flex flex-col md:flex-row items-center justify-between">
        <div className=" mb-6 md:mb-0 md:mr-6">
        <h2 className="text-5xl sm:text-6xl md:text-7xl font-bold mb-4" data-aos="fade-up" data-aos-delay="50"   >
            Do you want to explore<br />our outstanding work?
          </h2>
          <p className="text-gray-600 mb-6"  data-aos="fade-up" data-aos-delay="50">
          Choosing the right software development company
           can be challenging. At Solution Soul, we understand that 
           your business is unique and requires customized solutions. 
           That’s why we offer personalized solutions to each of our clients.
            Here are some reasons why you should choose Solution Soul for your 
            software development needs
          </p>
          <div className="flex flex-wrap gap-4">
            {contactlink.map((contactlink, index) => (

                <Link to={contactlink.Link}
                data-aos="fade-up"
    data-aos-delay={50 * (index + 1)} 
                target=" blank" className="flex bg-[#304f7c] text-white px-6 py-3 font-[700] rounded-full border-2 border-[#344f7c] overflow-hidden transition-transform duration-300 will-change-transform z-0 items-center justify-center group hover:text-[#344f7c] ">
              {contactlink.name} <FaArrowRight className="ml-2 h-4 w-4 transition-transform -rotate-45 duration-300 transform group-hover:rotate-0" />
              <span className="absolute inset-0 bg-white rounded-full transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0 will-change-transform z-[-1]"></span>
            </Link>
              

              
            ))}
          </div>
        </div>
        <BiGlobe className="w-16 h-16 md:w-24 md:h-24 text-[#379885]" />

      </div>
    </section>
  );
};

export default ChooseUsSection;
