  import service1 from "../assets/service-feature-img.jpg";
import service2 from "../assets/service-img-4.jpg";
import service3 from "../assets/service-img-3.jpg";
import service4 from "../assets/service-img-2.jpg";
import service5 from "../assets/service-img-5.jpg";
import service6 from "../assets/service-img-6.jpg";
import { RiCodeSSlashFill } from "react-icons/ri";
import { TbDeviceMobileCode } from "react-icons/tb";
import { FcSearch } from "react-icons/fc";
import { FaScrewdriverWrench } from "react-icons/fa6";
import { FaVideo } from "react-icons/fa";
import { HiOutlineDesktopComputer } from "react-icons/hi";
export const servicelistdata = [
    {
      title: 'Website Development',
      description: ' We specialize in creating modern, responsive websites that deliver a seamless user experience across all devices. Our team leverages industry-leading technologies like HTML, CSS, BootStrap, Javascript, PHP, Laravel, React.js, Node.js, Express.js and WordPress to build websites that are not only visually appealing but also highly functional and scalable. Whether you need a simple business site or a complex web application, we focus on providing custom solutions that meet your business objectives. From front-end design to back-end development, we ensure your site performs flawlessly and remains easy to manage. With a commitment to high-quality coding standards and the latest technology, we deliver websites that help your business grow and succeed online.',
      image: service1,
      icon:RiCodeSSlashFill,
      points: [
        '1,000+ Completed Projects',
        'User-Friendly Interface',
        'Content Migration',
        'High Performance',
        'Fast Load Time',
        'Easy Back-End Admin Panel',
        'Maintenance and Support',
      ],
    },
    {
      title: 'Mobile App Development',
      description: 'As a leading provider of mobile app development services, we specialize in turning innovative ideas into dynamic mobile applications that resonate with users. Our team of experienced developers utilizes the latest technologies and industry best practices to create high-quality applications for both Android and iOS platforms.We understand that a successful mobile app must offer a seamless user experience while incorporating robust functionality. Our approach begins with a thorough analysis of your requirements, followed by the design of a user-friendly interface that engages users. We focus on developing applications that not only meet but exceed client expectations, ensuring that each app is tailored to the specific needs of your business.',
      image: service2,
      icon:TbDeviceMobileCode,
      points: [
        '1,000+ Completed Projects',
        'Custom App Development',
        'Cross-Platform Compatibility',
        'Integration of Advanced Features',
        'User-Friendly Interface',
        'Thorough Testing and Quality Assurance',
        'High Performance',
        'Easy Back-End Admin Panel',
        'Maintenance and Support',
      ],
    },
    {
      title: 'SEO Services',
      description: 'Elevate your online presence with our comprehensive SEO services designed to enhance your website’s visibility and drive organic traffic. We utilize the latest techniques and strategies to optimize your site for search engines, ensuring you rank higher and attract more visitors.we specialize in providing top-notch SEO services that drive measurable results for your business. Our experienced team of SEO professionals is dedicated to enhancing your online visibility and helping you dominate search engine rankings.',
      image: service3,
      icon:FcSearch,
      points: [
        'Keyword Research',
        'On-Page Optimization',
        'Quality Content Creation',
        'Comprehensive SEO Audits',
        'Strategic Keyword Optimization',
        'Technical SEO Enhancements',
        'Content Strategy Development',
        'Analytics and Reporting',
      ],
    },
    {
      title: 'Data Scraping',
      description: 'Unlock valuable insights from diverse sources with our professional data scraping services. We help businesses gather, clean, and organize large volumes of data from websites, databases, and other platforms, transforming raw data into actionable intelligence. Whether you need competitive analysis, price monitoring, or market trends, our custom data scraping solutions deliver accurate and reliable results. Web scraping plays a pivotal role in supplying data for machine learning models, furthering the advancement of AI technology. For instance, scraping images from websites can feed computer vision algorithms, textual data can be used for natural language processing models, and customer behavior data can enhance recommendation systems. By automating the data collection process and scaling it to gather information from a wide range of sources, web scraping helps in creating robust, accurate, and well-trained AI models.',
      image: service4,
      icon:FaScrewdriverWrench,
      points: [
        'Custom Data Extraction',
        'Automated Data Collection',
        'Data Cleaning & Formatting',
        'Real-Time Updates',
        'Compliance & Security',
        'High Accuracy & Speed',
        'Structured Data Delivery',
        'Compliance & Security',
      ],
    },
    {
      title: 'Video Editing',
      description: `Enhance the quality of your video content with our professional video editing services. Whether you're creating promotional videos, corporate films, or social media content, we ensure your videos are engaging, polished, and visually compelling. Our team of skilled editors uses advanced techniques to craft seamless narratives, apply stunning effects, and integrate high-quality sound design, helping you captivate your audience and effectively convey your message.Our video editing services transform your raw footage into polished, professional content tailored to your needs. Whether you're producing corporate videos, social media clips, promotional materials, or personal projects, we ensure every frame aligns with your vision. With state-of-the-art tools and creative expertise, we enhance your videos by adding visual effects, smooth transitions, and clean edits that captivate your audience.
We focus on delivering videos that are not only visually stunning but also effective in communication. From narrative structure to post-production finishing touches, our team ensures that your message is conveyed with clarity and impact.`,
      image: service5,
      icon:FaVideo,
      points: [
        'High-Quality Video Editing',
        'Advanced Visual Effects',
        'Sound Design & Mixing',
        'Color Grading & Correction',
        'Fast Turnaround',
        'Professional Audio Mixing',
        'Motion Graphics & Titles',
        'Efficient Workflow',
      ],
    },
    {
      title: 'Graphics Design',
      description: `Our graphic design services provide creative and impactful solutions to help you visually communicate your brand’s message. Whether you're building a new brand identity, designing marketing materials, or creating digital assets, we craft designs that stand out and resonate with your target audience. From logos and brochures to social media graphics and website visuals, we ensure that every design is not only aesthetically pleasing but also strategically aligned with your business goals.We understand the importance of consistency across all channels, and our designs reflect your brand's personality and values, leaving a lasting impression.`,
      image: service6,
      icon:HiOutlineDesktopComputer,
      points: [
        'Custom Branding',
        'Print & Digital Design',
        'Website & UI/UX Design',
        'Illustration & Icon Design',
        'Infographics & Data Visualization',
        'Social Media Assets',
      ],
    },
  ];