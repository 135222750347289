import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Typed from 'typed.js';
import ChooseUsSection from '../../pages/ChooseUsSection';
import Marquee from 'react-fast-marquee';
import Testimonial from '../../pages/Testimonial';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import { servicelistdata } from '../assets/servicelistdata';
const OurService = () => {
  const formatServiceId = (title) => title.toLowerCase().replace(/\s+/g, '-');
  const [services, setServices] = useState([
    'Mobile App Development',
    'Web Development',
    'Graphic Design Services',
    'Custom Software Development',
    'Digital Marketing',
    'SEO Optimization'
  ]);

  

  useEffect(() => {
    AOS.init({ duration: 600, once: true });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0); // This will move the user to the top of the page
  }, []);
  
  useEffect(() => {
    const typed = new Typed('.typing', {
      strings: ['Our Services'],
      typeSpeed: 30,
      loop: false,
      showCursor: false,      
    });
    
    return () => {
      typed.destroy();
    };
  }, []);
  const getFirst40Words = (text) => {
    const words = text.split(' '); // Split the description into words
    if (words.length > 23) {
      return words.slice(0, 23).join(' ')  // Return first 40 words with ellipsis
    }
    return text; // If less than 40 words, return the original text
  };
  return (
    <div className='font-manrope'>
      
      <Helmet>
        <title>Our Services | Solution Soul</title>
        <meta
          name="description"
          content="Discover the services we offer, including web development,app development, SEO, and digital marketing to help grow your business."
        />
      </Helmet>

      
      <div className="relative ">
        <div  className="w-full  h-96 bg-[#304f7c] bg-no-repeat text-white" >
        <div className="absolute inset-0 flex items-center justify-center text-center"  >
          <h2 className="text-5xl md:text-6xl lg:text-7xl font-bold leading-[3.25rem] ">
            <span className="typing"></span>
          </h2>
        </div>
      </div>
      </div>
      

      {/* About Us Section */}
      <main >
       <div>

       <section className=" mx-auto px-4 md:px-6 lg:px-8 bg-white" >
     

      <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
      {servicelistdata.map((serviceslist, index) => (
  <div
    key={index}
    className="group bg-[#F7F8FD] rounded-3xl shadow-md border border-white overflow-hidden flex flex-col"
    data-aos="fade-up"
    data-aos-delay={100 * (index + 1)} // Adjust delay based on the index
  >
    <div className="flex flex-1 flex-col p-5">
      <div className="flex justify-between items-center border-b px-2 py-5">
        <h3 className="text-3xl font-semibold w-1/2">{serviceslist.title}</h3>
        <Link
          to={`/services/${formatServiceId(serviceslist.title)}`}
          className="w-14 h-14 flex items-center justify-center bg-[#304f7c] text-white rounded-full border border-[#344f7c] transition-transform duration-300 group-hover:text-white relative font-semibold overflow-hidden will-change-transform z-0 group"
        >
          <FaArrowRight className="transition-transform -rotate-45 duration-300 transform group-hover:rotate-0 text-2xl" />
          <span className="absolute inset-0 bg-[#304f7c] rounded-full transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0 will-change-transform z-[-1]"></span>
        </Link>
      </div>
      <p className="text-gray-500 px-2 py-4 ">  {getFirst40Words(serviceslist.description)}</p>
      <Link to={`/services/${formatServiceId(serviceslist.title)}`}>
      <img
        src={serviceslist.image}
        alt={serviceslist.title}
        loading="lazy"
        className="w-full object-cover rounded-2xl transition-transform duration-300 group-hover:scale-105"
      />
      </Link>
    </div>
  </div>
))}

      </div>
    </section>

       </div>
        <ChooseUsSection/>
        
      </main>
      <div className="w-full bg-[#304f7c] text-white py-4 overflow-hidden" >
      <Marquee gradient={false} speed={50}>
        {services.map((service, index) => (
          <div key={index} className="flex items-center mx-4">
            <span className="text-2xl font-bold">{service}</span>
            {index < services.length - 1 && (
              <span className="text-2xl mx-4">•</span>
            )}
          </div>
        ))}
      </Marquee>
    </div>
    <div>
      <Testimonial/>
    </div>
    </div>
  );
};

export default OurService;
