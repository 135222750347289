import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Typed from 'typed.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // This will move the user to the top of the page
      }, []);
      
  useEffect(() => {
    const typed = new Typed('.typing', {
      strings: ['Privacy Policy'],
      typeSpeed: 30,
      loop: false,
      showCursor: false,      
    });


    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className='font-manrope'>
         <Helmet>
        <title >Privacy Policy | Solution Soul</title>
        <meta
          name="description"
          content="Learn about Solution Soul, experts in web development, app development, SEO, and digital marketing. Discover how our team helps businesses grow through innovative solutions."
        />
      </Helmet>
      
      <div className="relative ">
        <div  className="w-full  h-96 bg-[#304f7c] bg-no-repeat text-white" >
        <div className="absolute inset-0 flex items-center justify-center text-center"  >
          <h2 className="text-5xl md:text-6xl lg:text-7xl font-bold leading-[3.25rem] ">
            <span className="typing"></span>
          </h2>
        </div>
      </div>
      </div>
<div>
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 ">
        <h1 className="text-lg font-bold leading-tight  mb-4 md:mb-0">
          Our Privacy Policy
        </h1>
        <p className="text-[15px] font-medium mb-8 mt-3">
        At Solution Soul, we value your privacy and are committed to protecting your personal information. This privacy policy outlines the types of information we collect, how we use that information, and how we protect your information.
        </p>
    </div>
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-12">
        <h1 className="text-lg font-bold leading-tight  mb-4 md:mb-0">
        Information We Collect:
        </h1>
        <p className="font-medium mt-3">
        We may collect the following information from our clients and website visitors:
        </p>
        <ul className="text-[15px] font-medium mb-8 mt-5 list-disc">
        <li className='mt-2'>Personal information such as name, email address, phone number, and company name </li>
        <li className='mt-2'>Business information such as business name, business type, and business address</li>
        <li className='mt-2'>Technical information such as IP address, browser type, and operating system</li>
        </ul>
    </div>

    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 ">
        <h1 className="text-lg font-bold leading-tight  mb-4 md:mb-0">
        How We Use Your Information:
        </h1>
        <p className="text-[15px] font-medium mb-8 mt-3">
        We use your information to provide our software development services and to communicate with you regarding your project. We may also use your information to send you marketing communications that may be of interest to you. You can opt-out of receiving these communications at any time.
        </p>
        <p className="text-[15px] font-medium mb-8 mt-3">
        We do not sell or share your information with third-party vendors for marketing purposes. We may share your information with our trusted third-party partners who assist us in providing our software development services.
        </p>
        <p className="text-[15px] font-medium mb-8 mt-3">
        We may disclose your information to comply with legal requirements, to protect our rights or property, or in the event of a merger or acquisition.
        </p>
    </div>
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 ">
        <h1 className="text-lg font-bold leading-tight  mb-4 md:mb-0">
        How We Protect Your Information:
        </h1>
        <p className="text-[15px] font-medium mb-8 mt-3">
        We take appropriate measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. We use industry-standard security measures such as encryption and secure servers to protect your information. We also limit access to your information to only those who need it to perform their duties.
        </p>
       
    </div>
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 ">
        <h1 className="text-lg font-bold leading-tight  mb-4 md:mb-0">
        Your Choices:
        </h1>
        <p className="text-[15px] font-medium mb-8 mt-3">
        You have the right to access and update your personal information. You can also opt-out of receiving marketing communications from us at any time. If you wish to access, update, or delete your information, please contact us at<Link to="mailto:info@solutionsoul.com" className='text-[#304f7c] font-semibold mx-1'> info@solutionsoul.com</Link>
        </p>
       
    </div>
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 ">
        <h1 className="text-lg font-bold leading-tight  mb-4 md:mb-0">
        Third-Party Websites:
        </h1>
        <p className="text-[15px] font-medium mb-8 mt-3">
        Our website may contain links to third-party websites that are not owned or operated by MetaViz. We are not responsible for the privacy practices or content of these websites. We recommend that you review the privacy policies of these websites before providing any personal information.
        </p>
       
    </div>
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 ">
        <h1 className="text-lg font-bold leading-tight  mb-4 md:mb-0">
        Updates to Privacy Policy:
        </h1>
        <p className="text-[15px] font-medium mb-8 mt-3">
        We may update this privacy policy from time to time. We will notify you of any changes by posting the updated policy on our website. We encourage you to review this policy periodically.
        </p>
       
    </div>
    <div className="max-w-6xl mx-auto px-4  sm:px-6 lg:px-8 mt-12 ">
        <h1 className="text-lg font-bold leading-tight  mb-4 md:mb-0">
        Contact Us:
        </h1>
        <p className="text-[15px] font-medium mb-8 mt-3">
        If you have any questions or concerns about our privacy policy, please contact us at
        <Link to="mailto:info@solutionsoul.com" className='text-[#304f7c] font-semibold mx-1'>info@solutionsoul.com</Link> We’re happy to answer any questions you may have and ensure that your personal information is protected.
        </p>
       
    </div>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
