import React from 'react'
import logo from '../components/assets/SolutionSoul Web.png'
import { Link } from 'react-router-dom'
import { FaBuilding, FaEnvelope, FaFacebook, FaLinkedin, FaYoutube } from 'react-icons/fa'
const Footer = () => {
  return (
    <footer className="bg-[#f7f8fd] py-12 px-4 font-manrope">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-24">
            <div className="space-y-4">
              <h2 className="text-3xl font-bold">
               <img src={logo}  alt='Logo' className='h-12 '/>
              </h2>
              <p className="text-gray-500 text-sm">
              Solution Soul is a renowned international software development company. We specialize in providing custom software development solutions to businesses worldwide. </p>
      </div>
            <div>
              <h3 className="text-lg font-bold mb-4 text-[#304f7c]">Main Menu</h3>
              <ul className="space-y-2 text-gray-500 font-semibold">
               <li className='transition-all duration-100 hover:text-[#379885]'>
                <Link to="/">Home</Link></li>
               <li className='transition-all duration-100 hover:text-[#379885]'>
                <Link to="about-us">About Us</Link></li>
               <li className='transition-all duration-100 hover:text-[#379885]'>
                <Link to="/portfolio">Portfolio</Link></li>
               <li className='transition-all duration-100 hover:text-[#379885]'>
                <Link to="/our-services">Our Service</Link></li>
               <li className=' transition-all duration-100 hover:text-[#379885]'>
                <Link to="/contact-us">Contact Us</Link></li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-bold mb-4 text-[#304f7c]">Quick Links</h3>
              <ul className="space-y-2 text-gray-500 font-semibold">
               <li className='transition-all duration-100 hover:text-[#379885]'>
                <Link to="/our-team">Our Team</Link></li>
               <li className='transition-all duration-100 hover:text-[#379885]'>
                <Link to="/terms-and-conditions">Terms and Conditions</Link></li>
               <li className='transition-all duration-100 hover:text-[#379885]'>
                <Link to="/privacy-policy">Privacy Policy</Link></li>
               <li className=' transition-all duration-100 hover:text-[#379885]'>
                <Link to="/faqs">FAQs</Link></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-bold mb-4 text-[#304f7c]">Contact Us</h3>
              <ul className="space-y-2 text-gray-500 font-semibold">
               <li className='transition-all duration-100 hover:text-[#304f7c] flex gap-3'>
                
                <FaBuilding  className='text-xl'/>
                Solution Soul</li>
               <li className='transition-all duration-100 hover:text-[#304f7c]'>
                <Link to="mailto:info@solutionsoul.com" className='flex gap-3'>
                <FaEnvelope className='text-xl ' />
                info@solutionsoul.com</Link></li></ul>
                <ul className='text-gray-500 font-semibold flex gap-3 mt-3'>
                <li >
                <Link to="https://www.youtube.com/@SolutionSoul1" target='blank'>
                <FaYoutube  className='text-red-500 text-3xl'/></Link></li>
              <li >
                <Link to="https://www.linkedin.com/company/solution-soul" target='blank'>
                <FaLinkedin className='text-blue-500 text-3xl'/></Link></li>
              <li >
                <Link to="https://www.facebook.com/people/SolutionSoul/61552713434856/" target='blank'>
                <FaFacebook className='text-indigo-600 text-3xl'/></Link></li></ul>
              
            </div>

          </div>
          <div className="mt-12 pt-8 border-t border-gray-200 flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-500 text-sm">Copyright © 2024 Solution Soul. All rights reserved.</p>
            <div className="flex space-x-4 mt-4 md:mt-0">
              <Link to="/privacy-policy" className="text-gray-500 text-sm hover:text-gray-700">
                Privacy Policy
              </Link>
              <Link to="/terms-and-conditions" className="text-gray-500 text-sm hover:text-gray-700">
              Terms and Conditions
              </Link>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default Footer
