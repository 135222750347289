import React, { useEffect } from 'react';
import aboutus from '../components/assets/about-us-img.jpg';
import { FaArrowRight } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';

const AboutUsSection = () => {
  useEffect(() => {
    AOS.init({ duration: 600, once: true });
  }, []);

  return (
    <div className="min-h-screen font-manrope">
      <div className="flex flex-col md:justify-center  md:items-start  md:text-left pt-8 pb-2 px-4 md:px-8" data-aos="fade-up">
        <h2 className="text-[#379885] text-xl font-semibold mb-4" >About Our Company</h2>
      <h1 className="mb-12 text-5xl font-bold leading-tight md:text-6xl lg:text-7xl">
        Websites that tell your brand's story
      </h1>
      </div>
      <main className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-8 mx-auto px-4 py-12 relative">
        <div className="flex justify-center items-center">
          <img
            src={aboutus}
            data-aos="fade-right"
            alt="Team working together"
            loading="lazy"
            className="rounded-3xl shadow-lg w-full max-w-full sm:max-w-full md:max-w-lg lg:max-w-lg"
          />
        </div>
        <div className="flex flex-col md:justify-center items-start md:items-start  md:text-left p-4 ">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-6">
            <p data-aos="fade-right">At Solution Soul</p>
          </h1>
          <p className="text-gray-600 mb-8" data-aos="fade-up" data-aos-delay="100">
            <span className='font-bold'>We</span> believe that technology has the power to transform businesses and change lives.
            That’s why we are dedicated to providing exceptional software development services to our clients.
            With over 20 years of combined experience, our team of skilled developers, designers, and engineers is committed
            to delivering high-quality software that meets and exceeds client expectations.
          </p>
          <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-3 gap-x-8 gap-y-5" data-aos="fade-up" data-aos-delay="100">
            {[
              'Ease of Scalability',
              'Instant Impact',
              ' Experience',
              'Time Zone Aligned',
              'Full Flexibility',
              'Proactive Support',
            ].map((item) => (
              <div key={item} className="flex items-center space-x-2">
                <MdKeyboardArrowRight className="w-5 h-5 text-white rounded-full bg-[#379885]" />
                <span className="text-gray-700">{item}</span>
              </div>
            ))}
          </div>
          <div className="flex flex-col mt-10 sm:flex-row justify-center md:justify-start space-y-4 sm:space-y-0 sm:space-x-4" data-aos="fade-up" data-aos-delay="50">
            <Link to="/contact-us" className="flex bg-[#304f7c] text-white px-6 py-3 font-semibold rounded-full border-2 border-[#344f7c] overflow-hidden transition-transform duration-300 will-change-transform z-0 items-center justify-center group hover:text-[#344f7c]">
              Free Consultation <FaArrowRight className="ml-2 h-4 w-4 transition-transform -rotate-45 duration-300 transform group-hover:rotate-0" />
              <span className="absolute inset-0 bg-white rounded-full transform -translate-x-full transition-transform duration-300 group-hover:translate-x-0 will-change-transform z-[-1]"></span>
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AboutUsSection;
